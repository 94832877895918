.progress-bar {
  display: flex;
  max-width: 500px;
  width: 90vw;
  flex-direction: column;
  justify-content: flex-end;

  p {
    width: 100%;
    text-align: right;
    color: #000000BF;
  }

  &_line {
    margin-top: 5px;
    position: relative;

    &_total {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 8px;
      border-radius: 4px;
      background-color: #54BE7340;
      width: 100%;
    }

    &_progress {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 8px;
      border-radius: 4px;
      background-color: $green;
    }
  }
}
