.sign-up-page {
  font-family: 'Mulish', sans-serif;
  background-color: $light-grey;
  text-align: center;
  letter-spacing: 0.2px;

  .input {
    background-color: $white;
  }

  .button_login {
    background-color: $light-grey;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 100px 90px;

    .content_col1 {
      max-width: 460px;
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 46px;
      line-height: 56px;
      font-weight: 700;
      color: $green;
      margin-bottom: 7px;
      word-break: break-word;
    }

    .text {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #6A6A6A;
      word-wrap: normal;

      a {
        color: $green;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .text.font-size-large {
      font-size: 16px;
    }

    .title-description {
      font-size: 17px;
      margin-bottom: 25px;
      line-height: normal;
    }

    .down-section {
      max-width: 443px;
      width: 100%;
      align-self: flex-start;
    }

    .already-have-account {
      margin-bottom: 30px;
      .button_log-in {
        height: 24px;
        border: none;
        color: $green;
        background-color: $white;
        font-weight: 700;
      }
    }
  }
}

@include desktop {
  .sign-up-recruiter-page .content_col1,
  .sign-up-school-page .content_col1 {
    margin-right: 10vw;
  }
}

@include laptop {
  .sign-up-page.sign-up-recruiter-page,
  .sign-up-page.sign-up-school-page,
  .claim-school-page {
    .content {
      padding: 5%;
      flex-direction: column;

      &_col1 {
        width: 95vw;

        .title {
          font-size: 40px;
          line-height: 50px;
        }
      }

      .field_wrapper {
        width: 100%;
      }
    }

    .sign-up-form {
      max-width: 440px;
    }
  }
}

@include mobile {
  .sign-up-page {
    .content {
      padding: 20px 30px;
    }
  }
}
