.admin-log-in-page {
  font-family: 'Mulish', sans-serif;

  .admin-log-in-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vh;
    background-color: $bg-1;

    .welcome-text {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .log-in-form {
      max-width: 400px;

      .field_wrapper {
        margin-bottom: 0;
      }

      .error-message {
        text-align: center;
      }
    }
  }
}
