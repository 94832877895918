.signature-container {
  align-items: center;
  width: 100%;

  .signature {
    border: 1px solid $green;
  }
  
  p, &_text {
    margin: 10px 0;
    font-size: 12px;
    color: $black;
  }

  &_text {
    text-align: justify;
  }

  ul {
    margin-right: 20px;
  }

  .success-message {
    margin-bottom: 10px;
  }

  .container-horizontal {
    max-width: 500px;
    width: 90vw;
    & > * + * {
      margin-left: 20px;
    }
    margin: 10px;

    .button {
      width: 100%;
      height: 42px;
      font-weight: 700;
      font-size: 14px;
      background: $white;
      color: $green;
      border: 1px solid $green;
    }
  }
}
