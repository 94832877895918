.add-substruct-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-add-subtract {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: $light-grey;
  margin-top: 10px;

  .btn {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
    border-radius: 50%;
    border: none;
    color: $white;
    background-color: $green;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: center;
    text-indent: 1px;

    &:disabled {
      border: 1px solid $medium-grey;
      background-color: $transpatent-light-grey;
      color: $grey;
      pointer-events: none;
    }

    &:active {
      background-color: darken($green, 5%);
    }
  }

  .input {
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 2px;
    text-align: center;
    font-weight: bold;

    @include tablet {
      max-width: 150px !important;
    }

    @include tablet {
      max-width: 100px !important;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .add-subtract-input-wrapper {
    display: flex;
    background-color: white;
    align-items: center;
    padding: 7px 60px;

    .add-subtract-input {
      border: none;
      outline: none;
      width: 30px;
      padding: 0px;
      text-align: center;
    }
  }
}

// disables number input arrows in chrome
.add-subtract-input::-webkit-outer-spin-button,
.add-subtract-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// disables number input arrows in firefox
.add-subtract-input {
    -moz-appearance: textfield;
}

.btn-section-horizontal {
  flex-direction: row;
  padding: 5px 20px;

  .btn {
    flex-basis: 31px;
  }

  .input {
    margin: 5px 20px;
  }
}

.btn-section-vertical {
  flex-direction: column-reverse;
  padding: 10px 5px;
  width: auto;

  .input {
    margin: 10px 5px;
  }

  .add-subtract-input-wrapper {
    padding: 7px 5px;
  }
}
