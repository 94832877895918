.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 667;

  &_item {
    background: $white;
    padding: 10px 15px 15px;
    font-size: 14px;
    text-align: left;
    width: 200px;
    border: none;
    cursor: pointer;
    color: $black;
    border: none;
    text-decoration: none;
  }

  &_item:hover,
  .link:hover {
    background: $light-grey;
  }
}

@include tablet {
  .dropdown-menu {
    width: 100%;
    left: 0;

    &_item {
      width: 100%;
      text-align: center;
    }
  }
}
