.school-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20%;

  @include laptop() {
    padding: 20px 9%;
  }

  @include tablet() {
    padding: 15px 5px;
  }

  &_name {
    margin-bottom: 20px;
    font-size: 48px;
    line-height: normal;
    text-align: center;
  }

  &_content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include tablet() {
      flex-direction: column;
    }

    p {
      margin: 0;
      font-size: 16px;
      word-break: break-word;
    }

    &_website {
      display: flex;
      justify-content: center;
      padding: 0 10px;
      text-align: center;
    }

    &_address {
      text-align: right;
    }

    @media (max-width: 425px) {
      &_contact-info,
      &_address {
        text-align: center;
      }
    }
  }
}