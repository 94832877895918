.subscription-form {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: $white;
  background-color: $green;
  text-align: center;
  padding: 27px 0;

  h3 {
    line-height: 40px;
    color: $white;

    @include mobile {
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  button {
    margin: 5px 25px;
    height: 35px;
    border: 0;
    background-color: $white;
    color: $green;
    font-weight: 700;
    padding: 0 15px;
  }
  
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    .fields {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 10px;

      input {
        height: 35px;
        border: 0;
        border-radius: 5px;
        margin: 5px;
        padding-left: 10px;
        background-color: rgb(232, 240, 254) !important;
      }
    }

    button {
      background-color: $green;
      color: $white;
    }

    .error-message {
      text-align: left;
    }
  }

  .pop-up_btn-close {
    margin: unset;
    height: unset;
  }

  button:disabled {
    cursor: not-allowed;
  }
}