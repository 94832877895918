.nav-buttons-for-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .button {
    width: 42px;
    height: 42px;
    background-color: $green;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    margin-right: 8px;
    border: none;

    &:last-child {
      margin-right: 0px;
    }

    @include tablet {
      width: 38px;
      height: 38px;
    }

    @include mobile {
      width: 32px;
      height: 32px;
    }
  }
  
  .button-active {
    background-color: $transparent-green-2;
  }
}
