.company-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  padding: 0 150px 100px 150px;
  background-color: $light-grey;
  letter-spacing: 0.2px;

  @media (max-width: 1400px) {
    padding: 0 70px 100px 70px;
  }

  @include tablet() {
    padding: 0 50px 50px 50px;
  }

  @media (max-width: 685px) {
    padding: unset;
    padding-bottom: 20px;
  }

  &_loader {
    margin-top: 57px;
  }

  &_title {
    margin: 57px 0 58px 0;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: $green;
    text-align: center;
  }

  .company-details {
    display: flex;
    flex-direction: column;
    padding: 40px 54px 60px 42px;
    background-color: $white;

    h2 {
      text-align: center;
    }
  }

  .company-details {
    &_vacation {
      display: flex;
      align-items: center;

      &_index {
        font-weight: 600;
        font-size: 16px;
      }

      &_values {
        margin-top: 10px;
        margin-left: 10px;
        border-left: 2px solid $black;
        padding: 0 10px;
      }
    }
  }

  .table-row {
    max-width: 650px;
    display: flex;

    & > * {
      width: 50%;
      word-break: break-word;
    }
  }

  .border {
    border-top: 1px dashed #a4a4a4;
  }

  &_go-back-button {
    margin: 77px 0 0 0;
    padding: 22px 85px;
    font-weight: 700;
    font-size: 14px;
    background-color: $green;
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }

  &_recruiters-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    padding: 15px;
    border-radius: 10px;
    background-color: $white;
  }

  table {
    max-width: 660px;
    border-collapse: separate;
    border-spacing: 2px;
    background-color: $light-grey;

    th, td {
      height: 42px;
      text-align: center;
      margin: 2px;
      background: $white;
      word-break: break-all;
    }
  
    th {
      border: 1px solid $green;
    }
  
    td {
      padding: 0 10px;
      color: $green;
    }
  }
}
