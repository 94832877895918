.search-schools-before-claim {
  flex-direction: column;

  &_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 68px;

    &_content {
      display: flex;

      .input {
        width: 355px;
        height: 42px;
        margin-right: 14px;
        padding: 0 0 0 16px;
        border: none;
      }
    
      .button_claim {
        width: 81px;
        height: 42px;
        font-weight: 700;
        border: none;
        background-color: $green;
        color: $white;
      }
    
      .button_claim:disabled {
        background-color: $grey;
        cursor: initial;
      }
    }
  }
}

@include tablet {
  .search-schools-before-claim {
    width: 100%;

    &_form {
      width: 100%;
      height: auto;
      justify-content: center;

      &_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
      }
    }

    .input {
      width: 75%;
      height: 30px;
      margin: 0;
      font-size: 11px;
    }

    .button_claim {
      width: 25%;
      height: 30px;
      padding: 0 3px;
      font-size: 10px;
    }
  }
}

@include mobile {
  .search-schools-before-claim {
    width: 100%;

    &_form {
      width: 100%;
      height: auto;
      justify-content: center;

      &_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
      }
    }

    .input {
      width: 75%;
      height: 30px;
      margin: 0;
      font-size: 10px;
    }

    .button_claim {
      width: 25%;
      height: 30px;
      padding: 0 3px;
      font-size: 10px;
    }
  }
}
