.admin-reports-page {
  font-family: 'Mulish', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  background-color: $light-grey;
  
  &_title {
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    color: $green;
    margin-bottom: 10px;
    text-align: center;

    @include mobile {
      font-size: 34px;
    }
  }

  &_report-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
    width: 90vw;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid $border-grey;
    background-color: $white;

    @include mobile {
      flex-direction: column;
    }

    &_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      & > * + * {
        margin-left: 10px;
      }

      button {
        height: 40px;
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        border: 1px solid $green;

        @include mobile {
          width: 100px;
        }
      }

      &_button-resolve {
        color: $white;
        background-color: $green;
      }
  
      &_button-view {
        color: $green;
        background-color: $white;
  
        &_link {
          color: $green;
          text-decoration: none;
        }
  
        &:hover {
          box-shadow: 0 0 2px $green;
        }
      }
    }
  }

  .nav-buttons-for-pagination {
    max-width: 500px;
    width: 90vw;
    flex-wrap: wrap;
    margin: 0 -10px;

    button {
      margin: 5px;
    }
  }
}
