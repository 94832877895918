.field {
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;

  .label {
    margin-left: 10px;
    font-size: 16px;
    line-height: 30px;
    color: #373F41;
  }

  input, textarea {
    box-sizing: border-box;
    border-radius: 2px;
    border: 2px solid $grey2;
    padding: 7px;
    width: 100%;
  }

  input[disabled],
  textarea[textarea] {
    border-color: $grey !important;
    background-color: $light-grey !important;
  }

  textarea {
    max-width: 400px;
    width: 70vw;
    height: 200px;
  }

  .field_wrapper {
    margin-bottom: 20px;

    .search_field_error {
      border-radius: 2px;
      border-color: $red !important;
      border: 2px solid $red;
    }
  }

  .input {
    max-width: 450px;
    font-size: 14px;

    &--small {
      font-style: italic;
      border: 2px solid $grey2;
      width: 100%;
    }

    &--large {
      height: 54px;
      padding-top: 6px;
      padding-left: 16px;
    }

    &--textarea {
      width: 100%;
      padding-top: 6px;
      padding-left: 16px;
    }

    &--number {
      width: 95px;
      height: 42px;
      padding-top: 6px;
      padding-left: 16px;
    }

    &_non-default-other-option {
      position: absolute;
      margin: 0;
      top: -80px;
      left: 400px;
      border: 1px $light-grey solid;
      width: 243px;
      height: 37px;
      padding-left: 10px;
      color: #7B7B7B;
      font-size: 14px;
      font-style: italic;

      + .error-message {
        position: absolute;
        top: -40px;
        left: 400px;
      }
    }
  }

  .radio-item, .radio-item_make-of-tractors {
    display: flex;
    padding: 0 6px;
    margin: 10px 0 0 0px;
    position: relative;
    color: $grey;
    font-weight: normal;

    input {
      z-index: 4;
      position: absolute;
      display: none;
    }

    label {
      display: flex;
    }

    .fake_button {
      display: inline-block;
      margin: 0 10px 0 0;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 11px;
      background: $transparent-green;
      border: 2px solid $transparent-green-border;
      box-sizing: border-box;
    }

    .fake_label {
      height: 24px;
    }

    .set-claimed-button {
      background: $transpatent-light-grey;
      border: 2px solid $transparent-green;
      color: $grey;
      margin: 5px 0;
      padding: 5px;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .active-board-search {
      background: $transparent-green-2;
      border: 2px solid $dark-green;
    }

    .checkbox {
      border-radius: 4px;
    }

    input[type=radio]:checked + label .fake_button {
      background: $white;
      border: 5px solid $green;
    }

    input[type=checkbox]:checked + label .fake_button.checkbox {
      border: 5px solid $green;
      background: $green url(../../images/white-check.png) no-repeat scroll 50%;
    }

    .fake_button.disabled {
      border-color: $grey !important;
      background: $transpatent-light-grey !important;
    }
  }

  .checkbox-item {
    display: flex;
    padding: 0 6px;
    margin: 10px 0 0 0px;
    position: relative;
    color: $grey;
    font-weight: normal;

    input {
      z-index: 4;
      position: absolute;
      display: none;
    }

    label {
      display: flex;
    }

    .fake_button-checkbox {
      display: inline-block;
      margin: 0 10px 0 0;
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      border-radius: 4px;
      background: $transparent-green;
      border: 2px solid $transparent-green-border;
      box-sizing: border-box;
    }

    .fake_button-radio {
      display: inline-block;
      margin: 0 10px 0 0;
      width: 24px;
      height: 24px;
      border-radius: 11px;
      background: $transparent-green;
      border: 2px solid $transparent-green-border;
      box-sizing: border-box;
    }

    input[type=checkbox]:checked + label .fake_button-checkbox.checkbox {
      border: 5px solid $green;
      background: $green url(../../images/white-check.png) no-repeat scroll 50%;
    }

    input[type=checkbox]:checked + .fake_button-checkbox {
      border: 5px solid $green;
      background: $green url(../../images/white-check.png) no-repeat scroll 50%;
    }

    input[type=radio]:checked + .fake_button-radio {
      border: 5px solid $green;
      background: $green url(../../images/white-check.png) no-repeat scroll 50%;
    }

    .fake_button-checkbox.disabled {
      border-color: $grey !important;
      background: $transpatent-light-grey !important;
    }

    &:disabled {
      background-color: bisque;
    }
  }

  .pay-for-experienced {
    .label {
      display: block;
      text-align: center;
    }
  }

  .text-component {
    p {
      margin: 10px 0;
      font-size: 12px;
      color: $black;
      text-align: justify;
    }
  }

  .date-picker_header .button-arrow {
    border: none;
    margin: 0 5px;
    font-weight: 700;
  }
}



.field_columns {
  display: grid;
  grid-template-columns: 45% 55%;
  align-items: flex-start;
}

.field_flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > .field_wrapper {
    margin-left: 45%;
  }

  .label {
    align-self: flex-start;
  }
}

.field_error {
  .fake_button,
  .fake_button-checkbox {
    border-color: $red !important;
    background: $transpatent-light-red !important;
  }

  .wrapper-add-subtract {
    .btn {
      background-color: $red;
    }

    input {
      color: $red;
    }

    .add-subtract-unit {
      color: $red;
    }
  }

  input, textarea {
    border: 1px solid $red;
  }

  .dropdown_input, .number-dropdown_input {
    border: 1px solid $red !important;
  }

  .number-dropdown_input {
    background: url(../../images/dropdown-arrow-red.png) $white no-repeat 9px 12px !important;
  }

  .dropdown_input {
    background: url(../../images/dropdown-arrow-red.png) $white no-repeat 10px 16px !important;
  }

  .dropdown_span, .number-dropdown_span {
    color: $red !important;
  }
}

.field_wrapper_horizontal {
  .error-message {
    text-align: center;
  }
}

.field_center {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  align-items: center;
  flex-direction: column;

  .label {
    margin-left: 0px;
  }

  .label[for="autoRenew"] {
    text-align: center;
  }

  .field_nested_input,
  .radio-item {
    justify-content: center;
  }

  .field_wrapper span {
    flex-direction: column;
  }

  .horizontal {
    display: flex;
    align-items: center;
  }

  .error-message {
    text-align: center;
    padding-left: 0px;
  }
}

.field_radio_horizontal {
  display: flex;
  justify-content: center;

  > div {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

.field_radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .radio-items-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .fake_label {
      text-align: left;
    }
  }
}

.field_nested_radio {
  position: relative;
}

.field_nested_input {
  display: flex;

  label.label {
    margin-left: 0px;
  }

  input {
    width: 100px;
    margin-left: 10px;
  }

  .label-end {
    margin-left: 10px;
  }
}


.field_nested_string {
  position: relative;
  color: #373F41;
  font-size: 16px;
  line-height: 24px;
  height: 20px;

  > span {
    position: absolute;
    width: 350px;
    top: 10px;
    left: 110px;
  }
}

.field_nested_checkbox {
  margin-left: 50px;

  @include tablet {
    margin-left: 0;
  }
}

.price-input-range {
  display: flex;
  flex-direction: row !important;

  @include tablet {
    margin-top: 15px;
  }

  span {
    margin: 0 5px;
  }

  .price-input {
    input {
      width: 100px !important;
    }

    &::before {
      top: 2px;
    }
  }
}

.price-input {
  position: relative;

  input {
    padding-left: 20px !important;
    margin-left: 10px;
    margin-bottom: 0px;
    max-width: 90px;
    margin-top: -5px;
  }

  &::before {
    content: "$";
    left: 18px;
    top: -2px;
    position: absolute;
  }
}

.number-dropdown {
  position: relative;
  flex-direction: column;
  align-items: center;

  &_input {
    border: 1px solid $green;
    border-radius: 2px;
    width: 65px;
    height: 27px;
    padding-left: 27px;
    font-weight: 700;
    font-size: 14px;
    appearance: none;
    background: url(../../images/dropdown-arrow.png) no-repeat 10px 11px;
    margin: 0 8px;

    &:disabled {
      border: 1px solid $grey;
      background: url(../../images/dropdown-arrow-grey.png) no-repeat 10px 11px;
    }
  }

  &_input:disabled + &_span {
    color: $grey;
  }

  &_span {
    position: absolute;
    color: $green;
    font-size: 8px;
    line-height: 10px;
    background-color: $white;
    top: -8px;
    left: 15px;
    padding: 1px 3px;
    z-index: 600;
  }
}

.dropdown {
  &_input {
    border: 1px solid $green;
    border-radius: 2px;
    width: 90px;
    height: 37px;
    padding-left: 27px;
    font-weight: 700;
    font-size: 14px;
    appearance: none;
    background: url(../../images/dropdown-arrow.png) $white no-repeat 10px 16px;

    &:disabled {
      border: 1px solid $grey;
      background: url(../../images/dropdown-arrow-grey.png) $white no-repeat 10px 16px;
    }
  }

  &_span {
    position: absolute;
    color: $green;
    font-size: 8px;
    line-height: 10px;
    background-color: $white;
    left: 325px;
    top: 25px;
    padding: 1px 3px;
    z-index: 600;
    border-radius: 5px;

    &_disabled {
      color: $grey;
    }
  }
}

.nested_form {
  margin-left: 40px;
}

.error-message {
  color: $red;
  font-size: 90%;
  padding-left: 18px;
  margin-top: 10px;
}

.number-of-paid-holidays {
  position: relative;
  height: 100px;
  font-weight: 600;

  select {
    display: none;
  }

  .select-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border: 1px solid rgba(17, 17, 17, 0.50);
    border-radius: 4px;
    margin-top: 20px;
    padding: 0 17px;

    .select-title {
      display: flex;
      flex-direction: column;

      .selected-value {
        font-weight: bold;
      }
    }

    div span {
      font-weight: normal;
      color: #373F41;
    }

    .button_toggle {
      border: none;
      width: 12px;
      height: 7px;
    }

    .button_up {
      background: url(../../images/holidays-arrow-up.png);
    }

    .button_down {
      background: url(../../images/holidays-arrow-down.png);
    }
  }

  .select-selected-disabled {
    pointer-events: none;
  }

  .select-items {
    overflow-y: scroll;
    height: 150px;
    border: 1px solid rgba(17, 17, 17, 0.50);
    margin-top: 4px;
    border-radius: 4px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);

    &_item {
      display: flex;
      align-items: center;
      padding-left: 17px;
      height: 44px;
      font-weight: 600;

      &:hover {
        background: $light-grey;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.input--no-arrows::-webkit-outer-spin-button,
.input--no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input--no-arrows[type=number] {
  -moz-appearance: textfield;
}

.error-message-homepage {
  padding-left: 0;
}
