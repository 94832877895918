.hourly-pay-structure {
  display: flex;
  flex-direction: column;
  width: 460px;

  h4 {
    text-align: center;
  }

  .dropdown {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $light-grey;
    width: 110px;
    height: 120px;
    margin-top: 10px;

    &_span {
      top: 37px;
      left: 37px;
    }
  }

  .button_close {
    margin-left: 13px;
    margin-top: 10px;
    border: none;
    width: 10px;
    height: 10px;
    padding: 0;
    background: url(../../images/btn_close.png) no-repeat;

    &:disabled {
      pointer-events: none;
      background: url(../../images/btn_close-grey.png) no-repeat;
    }
  }

  .button_add {
    align-self: flex-end;
    width: 10px;
    height: 10px;
    margin-right: 20px;
    margin-bottom: 5px;
    padding: 0;
    border: none;
    background: url(../../images/btn_add.png) no-repeat;

    &:disabled {
      pointer-events: none;
      background: url(../../images/btn_add-grey.png) no-repeat;
    }
  }

  &_row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;

    @include tablet {
      flex-wrap: wrap;
      margin: 10px 0;
    }

    span {
      min-width: max-content;
      margin-right: 10px;
    }

    .wrapper-add-subtract {
      .input {
        border: 1px solid $green;
        margin: 5px;
      }

      .input-disabled {
        border: 1px solid $grey;
      }

      .add-subtract-unit {
        margin-right: 0px;
      }
    }
  }
}

@include mobile {
  .hourly-pay-structure {
    max-width: 95vw;
    margin: 15px 0;

    &_row {
      flex-direction: column;
    }
  }
}

@media (max-width: 275px) {
  .hourly-pay-structure_row_upper-section {
    flex-direction: column;
  }
}
