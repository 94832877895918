button {
  cursor: pointer;
}

button:disabled {
  cursor: initial;
}

.container-horizontal {
  display: flex;
  flex-direction: row;
}

.container-vertical {
  display: flex;
  flex-direction: column;
}

.loader {
  text-align: center;
  margin: 0 auto;
}

.success-message {
  color: $green;
}

.is-hidden {
  display: none;
}

.bold {
  font-weight: 700;
}

.normal {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.underscore {
  text-decoration: underline;
}
