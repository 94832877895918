.pop-up {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 701;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $transpatent-black;
  font-family: "Mulish", sans-serif;

  &_content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    padding: 25px;
    border-radius: 16px;
    max-width: 100%;

    @include mobile {
      padding: 10px;
    }

    &_link {
      color: $black;
      font-weight: 700;
      text-decoration: none;

      &_hide {
        display: none;
      }
    }
  }

  &_content.default-corners {
    border-radius: 0px;
  }

  &_title {
    font-size: 19px;
    line-height: 24px;
    font-weight: 700;
    color: $black;
    text-align: center;
    margin-bottom: 10px;
  }

  &_text {
    font-size: 14px;
    text-align: center;
    color: $medium-grey;
  }

  &_btn {
    border: none;
    min-height: 42px;
    height: fit-content;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    
    margin-top: 10px;

    &-log-in {
      padding: 5px;
      font-size: 20px;
      font-weight: 700;
      transition: all 0.2s;

      &:hover {
        background-color: lighten($dark-green, 10%);
      }
    }

    &-green {
      background: $green;
      color: $white;
      border: none;

      &:disabled {
        background: $transparent-green-2;
      }
    }

    &-gray {
      background: $grey;
      color: $white;
      border: none;

      &:disabled {
        background: $transparent-green-2;
      }
    }

    &-white {
      background: $white;
      color: #00000080;
      border: 1px solid #56678942;
    }

    &-red {
      background: $red;
      color: $white;
    }

    &-close {
      position: absolute;
      background: none;
      border: none;
      right: 15px;
      top: 15px;
    }
  }

  .container-horizontal {
    width: 100%;
    & > * + * {
      margin-left: 20px;
    }
  }
}

// LogInModal
.login-as-pop-up .pop-up_content {
  width: 380px;
  height: auto;
  text-align: center;

  .container-vertical {
    padding: 10px;
    width: 100%;

    h3 {
      margin: 10px 0 20px;
    }
  }
}

// RepresentativeLogInModal
// RecruiterLogInModal
.recruiter-login-pop-up .pop-up_content,
.representative-login-pop-up .pop-up_content {
  width: 800px;
  height: 460px;
  display: flex;
  align-content: center;
  padding: 0;

  > div {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sign-up-section {
    background: url(../images/pexels-minh-977213.jpg);
    background-size: auto 100%;
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: lighten;
    padding: 0 5%;
  }

  .login-section {
    padding: 25px;
    font-size: 14px;
    text-align: center;

    .field {
      margin-bottom: 0;

      &_wrapper {
        input {
          background: none;
          padding: 2px 10px;
          margin: 0;
          width: 100% !important;
          height: 42px;
        }
      }
    }
  }
}

@media (max-height: 460px) {
  .recruiter-login-pop-up .pop-up_content,
  .representative-login-pop-up .pop-up_content {
    height: 100%;
  }
}

@media (max-width: 530px) {
  .recruiter-login-pop-up .pop-up_content,
  .representative-login-pop-up .pop-up_content {
    .sign-up-section {
      display: none;
    }

    > div {
      width: 80%;
    }

    .pop-up_btn {
      margin: 20px 0;
    }
  }
}

@include mobile {
  .recruiter-login-pop-up .pop-up_content,
  .representative-login-pop-up .pop-up_content {
    > div {
      width: 100%;
    }
  }
}


// CongratsPopUp
.congrats-pop-up {
  padding: 42px 45px;
  letter-spacing: 0.2px;

  .pop-up_content {
    margin: 0;
    flex-direction: row;
    height: min-content;
    max-width: 1000px;
    width: 80%;
    box-shadow: 0px 0px 15px $transpatent-black;

    .content_column-left {
      align-items: center;
      & > * + * {
        margin-top: 20px;
      }

      .title {
        font-size: 46px;
        line-height: 56px;
        font-weight: 700;
        color: $green;
        margin-top: 68px;
      }

      .text {
        max-width: 443px;
        font-size: 16px;
        line-height: 42px;
        letter-spacing: 0.1px;
        font-weight: 700;
        color: #373F41;
      }

      .container-horizontal:last-child {
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 71px;
      }

      .link {
        margin: 0;
        height: 24px;
        border: none;
        color: $green;
        background-color: $white;
        font-weight: 700;
        text-decoration: none;
      }
    }

    .content_column-left,
    .content_column-right {
      width: 45%;
    }
  }
}

@include laptop {
  .congrats-pop-up .pop-up_content {
    width: 100%;

    .content_column-left .title {
      font-size: 36px;
    }
  }
}

@media (max-width: 500px) {
  .congrats-pop-up {
    height: 100%;
    padding: 0;
  
    .pop-up_content {
      height: 100%;
      border-radius: 0;

      .content_column-left {
        width: 90%;
        & > * + * {
          margin-top: 10px;
        }
        
        .title {
          margin-top: 0;
        }
      }
    }
  }
}

@include mobile {
  .congrats-pop-up .pop-up_content .content_column-left .title {
    font-size: 30px;
  }
}

// alreadyExistsPopUp.jsx
.already-exist-pop-up .pop-up_content {
  height: 322px;
  width: 328px;
}

// ReportResolvePopUp
.report-resolve-pop-up .pop-up_text {
  width: 328px;
}

.report-resolve-pop-up {
  button {
    margin-bottom: 2px;
  }
}

// PasswordEnterPopUp
// TemplateSavePopUp
// ReportCreatePopUp
.pop-up-with-form {
  .pop-up_content {
    width: 400px;
  }

  button:disabled {
    background: $transparent-green-2;
    cursor: not-allowed;
  }

  .field_wrapper {
    width: 100%;

    .input {
      width: 100%;
      height: 56px;
      mix-blend-mode: normal;
      border: 1px solid #56678926;
      box-sizing: border-box;
      padding-left: 14px;
      font-size: 16px;
      font-weight: 600;
      color: $black;
    }

    .input-incorrect {
      border: 2px solid $red;
      color: $red;
    }

    &_incorrect-password {
      text-align: center;
      color: $red;
    }

    .error-message {
      color: $red;
      font-size: 90%;
      margin-top: 10px;
      padding-left: 0;
    }
  }
}

@include mobile {
  .pop-up-with-form .pop-up_content {
    height: 100%;
    border-radius: 0;
  }
}

// TemplateSelectPopUp
.tempate-select-pop-up {
  &_list {
    width: 100%;
    height: 280px;
    overflow-y: auto;

    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;

      .pop-up_btn {
        width: 50px;
      }
    }
  }
}

@include mobile {
  .tempate-select-pop-up .pop-up_content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

// ClaimedSchoolPopUp
// UnclaimedSchoolPopUp
// RepresentativeEmailInUsePopUp
// SchoolIsNotInSystemPopUp
// SwitchedMainRecruiterPopUp
.claimed-school-pop-up,
.unclaimed-school-pop-up,
.email-in-use-pop-up,
.switched-main-recruiter-pop-up {
  .pop-up_text {
    margin: 15px 0;
  }

  .pop-up_btn {
    width: 280px;
  }
}

.claimed-school-pop-up .pop-up_text {
  max-width: 530px;
}

// SettingsConfirmationPopUp
.confirmation-pop-up {
  .pop-up_title {
    max-width: 450px;
    text-align: center;
  }

  .container-horizontal {
    width: 100%;
    & > * + * {
      margin-right: 20px;
    }
  }
}

//ChooseSchoolsBeforeClaimPopUp
.choose-schools-before-claim-popup {
  .pop-up_title {
    margin-top: 20px;
      max-width: 400px;
  }

  table {
    .choose-schools-table-wrapper {
      overflow-y: scroll;
      height: 315px;
    }

    @include mobile {
      tr {
        display: flex;
      }
      .choose-schools-table-wrapper {
        tr {
          display: table-row;
        }
      }
    }

    tr td:last-child {
      word-break: break-all;
    }

    th, td {
      padding: 5px;
    }
  
    .field {
      width: min-content;
    }
  
    .checkbox-item {
      margin: 0;
    }
  }
}

@media (max-height: 460px) {
  .choose-schools-before-claim-popup .pop-up_content {
    flex-wrap: nowrap;
    height: 95vh;

    .choose-schools-table-wrapper {
      height: 55vh;
    }
  }
}

.choose-companies-before-claim-popup {
  .pop-up_content {
    table {
      width: 100%;
      table-layout: auto;
      text-align: center;
    }

    .pop-up_btn-green {
      padding: 10px 15px;
    }

    .field_radio {
      flex-direction: unset;
    }
  }

  .fake_button {
    margin: unset !important;
  }
}