.upload-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 36px;
  }

  &_container {
    height: 185px;
    width: 100%;
    position: relative;
  }

  .ant-upload-list-picture .ant-upload-list-item-error,
  .ant-upload-list-picture-card .ant-upload-list-item-error {
    border: 1px solid $light-grey !important;
  }

  .ant-upload-list-item-actions a {
    display: none;
  }

  .ant-upload-list-picture-card {
    display: flex !important;
    justify-content: center !important;
  }

  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container,
  .ant-upload-list-picture .ant-upload-list-item-error,
  .ant-upload-list-picture-card .ant-upload-list-item-error {
    width: 204px !important;
    height: 204px !important;
    transition: none !important;
  }
}
