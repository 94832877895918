.job-card {
  position: relative;
  display: flex;
  background: $white;
  padding-left: 31px;
  padding-right: 27px;
  border: 1px solid $border-grey;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 20px;

  p {
    margin: 0;
  }

  &_info,
  &_all-buttons,
  .main-container,
  .details-container,
  .details-and-type-container,
  .buttons,
  .text-container,
  .type {
    display: flex;
  }

  .no-avatar {
    .job-card_img {
      display: none;
    }

    .details-and-type-container.rows,
    .details-and-type-container.table {
      @media (max-width: 450px) {
        margin-left: unset;
      }
    }
  }

  &_info {
    flex-direction: column;
    justify-content: space-between;

    .main-container {
      @media (max-width: 450px) {
        flex-wrap: wrap;
      }

      .recruiter-job-card_img,
      .recruiter-job-card_img img,
      .job-card_img,
      .job-card_img img {
        border-radius: 50%;
      }

      .img-company-job-card.no-avatar,
      .recruiter-job-card_img.no-avatar {
        background: $grey;
      }

      .recruiter-job-card_img,
      .recruiter-job-card_img img {
        height: 80px;
        min-height: 80px;
        width: 80px;
        min-width: 80px;
      }

      .job-card_img img {
        min-height: 50px;
        height: 50px;
        min-width: 50px;
        width: 50px;
        margin-top: 5px;
        margin-right: 5px;
      }

      .text-container {
        flex-direction: column;
        margin-right: 40px;

        a {
          color: $black;
        }

        @media (max-width: 450px) {
          margin-left: unset;
        }

        @include mobile() {
          .created-by {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
          }
        }

        .school-name-and-location-container {
          display: flex;
          flex-wrap: wrap;

          .school-name {
            color: $green;
            margin-right: 10px;
            font-weight: 700;
          }
        }

        .position {
          font-size: 20px;
          font-weight: 700;
        }

        .company {
          cursor: pointer;
        }

        .company:hover {
          text-decoration: underline;
        }

        .location {
          background: url(../../../images/location.png) no-repeat scroll 0 6px;
          font-weight: 600;
          padding-left: 15px;
        }
      }
    }

    .details-and-type-container.rows {
      flex-direction: column;

      @media (max-width: 450px) {
        margin-left: unset;
      }
    }

    .details-and-type-container.table {
      flex-direction: column-reverse;
      margin-bottom: 20px;
    }

    .details-container {
      flex-wrap: wrap;

      > p {
        margin: 0 5px;

        @include mobile() {
          display: flex;
          flex-direction: column;
          align-content: flex-start;
        }
      }

      .wages {
        font-weight: 700;
      }

      .time {
        padding-left: 15px;
        background: url(../../../images/time.png) no-repeat scroll 0 6px;
      }

      .date {
        padding-left: 15px;
        background: url(../../../images/date.png) no-repeat scroll 0 6px;
      }
    }

    .details-container.rows {
      margin: 10px -10px;
      & > * {
        margin: 0 10px;
      }
    }

    .type {
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 27px;
      font-weight: 700;
      color: $green;
      background: $transparent-green;
    }

    .type.table {
      margin: 15px 0 15px 0;
    }
  }

  &_all-buttons {
    justify-content: space-between;

    .nav-buttons {
      display: flex;
      align-items: flex-start;

      .button_settings.rows {
        width: 42px;
        height: 42px;
        background: url(../../../images/btn_settings.png) no-repeat 50%;
        font-weight: 700;
        border: 1px solid $green;
      }

      .button_settings.table {
        position: absolute;
        top: 25px;
        right: 27px;
        width: 42px;
        height: 42px;
        background: url(../../../images/btn_settings.png) no-repeat 50%;
        font-weight: 700;
        border: 1px solid $green;
      }
    }

    .buttons {
      align-items: center;
      flex-wrap: wrap;

      & > * + * {
        margin-left: 20px;
      }

      .schools-not-in-system-pop-up {
        margin-left: 0
      }

      @media (max-width: 1300px) {
        & > * + * {
          margin-left: 5px;
        }
      }

      button,
      .button {
        height: 42px;
      }

      .button_link {
        text-decoration: none;
        margin-top: 5px;
      }

      .button_settings {
        width: 42px;
        background: url(../../../images/btn_settings.png) no-repeat 50%;
      }

      .button_apply.btn_disabled {
        color: $grey !important;
        background-color: $light-grey !important;
        border-color: $light-grey !important;
      }

      .button_apply {
        width: 129px;
        background: $green;
        color: $white;
      }

      .button_more-info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 129px;
        background: $white;
        color: $green;
        text-decoration: none;
      }

      .button_settings,
      .button_apply,
      .button_more-info {
        font-weight: 700;
        border: 1px solid $green;
      }

      .status-awaiting-approval {
        color: $status-awaiting-approval;
      }

      .status-live {
        color: $green;
      }

      .status-rejected {
        color: $status-rejected;
      }

      .status-awaiting-approval,
      .status-live,
      .status-rejected,
      .status-deleted {
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }

  &_all-buttons.rows {
    flex-direction: column;
    align-items: flex-end;
  }

  &_all-buttons.table {
    align-items: center;

    @media (max-width: 1450px) {
      .buttons {
        width: 100%;
      }
    }
  }

  .dropdown-menu {
    top: 52px;
    right: 15px;

    @media (max-width: 1300px) {
      top: 52px;
      right: 15px;
    }

    @include mobile {
      width: unset;
      left: unset;
    }

    @include tablet {
      width: unset;
      left: unset;
    }

    &_item {
      text-align: left;
    }

    .field {
      margin: 0;

      .checkbox-item {
        padding: 0;
        color: $black;

        p {
          margin-right: 50px;
        }
      }
    }
  }
}

.job-card.rows {
  width: 100%;
  padding: 15px;
  
  @include mobile {
    padding: 10px 5px;
  }

  .text-container {
    margin-right: unset;
  }

  .job-card_info {
    width: 50%;
  
    @include laptop() {
      width: 60%;
    }
  }
  
  .job-card_all-buttons {
    width: 50%;
  
    @include laptop() {
      width: 40%;
    }

    .buttons {
      @include laptop() {
        justify-content: end;
      }
    }
  }
}

.job-card.table {
  width: 48%;
  flex-direction: column;
  padding: 25px;
}

.button_apply.btn_disabled {
  color: $grey !important;
  background-color: $light-grey !important;
  border-color: $light-grey !important;
}
