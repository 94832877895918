.import-schools-page {
  font-family: 'Mulish', sans-serif;
  width: 100%;
  height: 100%;
  padding: 42px 45px 42px 52px;
  background-color: $light-grey;
  text-align: center;
  letter-spacing: 0.2px;

  .content {
    width: 100%;
    height: 100%;
    background-color: $white;
    box-shadow: 0px 0px 15px $transpatent-black;
    padding: 5vh;

    h1 {
      margin: 30px;
    }

    .button_import {
      margin-top: 39px;
      margin-bottom: 21px;
      max-width: 460px;
      width: 100%;
      height: 42px;
      border: none;
      background: $green;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
