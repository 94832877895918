$white:        #fff;

$black:        #000;
$transpatent-black: #00000033;

$light-blue:   #0084ff;

$green:        #54BE73;
$dark-green:   #36814c;
$transparent-green: #54BE7333;
$transparent-green-2: #54be747e;
$transparent-green-border: #54BE733D;

$grey:         #858585;
$grey2:        #11111152;
$light-grey:   #F2F2F2;
$medium-grey:  #737B7D;
$dark-grey:    #282C35;
$transpatent-light-grey: #d6d6d60f;

$red:          #db524e;
$transparent-red: #db534e86;
$transpatent-light-red: #db524e0a;

$bg-1:         #F0F0F0;
$input-bg:     #F8FAFC;
$border-grey:  #E4E4E4;

$status-awaiting-approval: #ff9900a6;
$status-rejected: #ff0000a6;

$mobile-header-height: 60px;
$standard-header-height: 88px;
$dropdown-item-height: 62px;
