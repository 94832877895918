.referral-create {
  .field_wrapper {
    width: 100%;
  }

  .referal-time {
    .horizontal {
      display: flex;
      align-content: center;
      justify-content: center;

      .field {
        width: unset;
      }

      .field_wrapper {
        margin: 0;
      }

      .wrapper-add-subtract {
        margin-top: unset;
      }

      .dropdown_input {
        height: 55px;
        background-position-y: 25px;
      }
      @include mobile() {
        .btn-section-horizontal {
          flex-direction: column-reverse;
          padding: 10px 5px;
          width: auto;
        
          .input {
            margin: 10px 5px;
          }
        
          .add-subtract-input-wrapper {
            padding: 7px 5px;
          }
        }
      }
    }
  }

  .pop-up_btn-green {
    height: 100%;
    width: 100%;
  }

  .pop-up_btn-red {
    height: 100%;
    width: 100%;
  }

  .settings-page_table {
    word-break: break-word;
  }
}