@mixin mobile {
  @media (max-width: 415px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 821px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
