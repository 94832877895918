.settings-page {
  position: relative;
  word-wrap: normal;
  padding: 5%;

  &_title {
    font-weight: 600;
    font-size: 16px;
    margin: 20px 0 20px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .content_centered, .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_buttons {
    display: flex;
    margin: -20px;
    & > * {
      margin: 10px;
    }
  }


  #qr_code_with_school_name {
    z-index: 600;
    zoom: 0.5;
  }

  .button {
    font-weight: 700;
    max-width: 235px;
    width: 18vw;
    height: 62px;
    padding: 0 10px;
    background: $green;
    color: $white;
    border: none;
    margin-top: 80px;
  }

  .button:disabled, .button_search:disabled {
    background-color: $transparent-green-2;
  }

  .company-edit-form .button_control {
    margin-top: 20px;
  }

  &_search {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content;
    margin: 10px 0 20px;
    z-index: 10;

    .input_search {
      width: 355px;
      background: #EFEFEF;
      color: #737B7D;
      font-style: italic;
    }

    .button_search {
      height: 42px;
      font-weight: 700;
      background: $green;
      color: $white;
    }

    .input_search, .button_search {
      height: 42px;
      padding: 0 16px;
      border: none;
    }
  }

  .message {
    color: $red;
  }

  .success-message {
    margin-top: 20px;
  }

  &_table {
    max-height: 500px;
    overflow-y: auto;

    table {
      border-collapse: separate;
      border-spacing: 2px;
    }

    th {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    th, td {
      height: 42px;
      text-align: center;
      margin: 2px;
    }

    .th-small-width {
      width: 42px;
    }

    .th-medium-width {
      width: 118px;
    }

    .th-large-width {
      width: 286px;
    }

    .td-green-color {
      color: $green;
    }

    .th-white-bg {
      background: $white;
      border: 1px solid $green;
    }

    td:not(.button-wrapper), .th-grey-bg {
      background: #F4F4F4;
    }

    .button_unblock, button {
      color: $white;
      border: none;
    }

    .button_green, .button-wrapper-green {
      background: $green;
    }

    .button_green {
      width: 100%;
      height: 100%;
    }

    .button_red, .button-wrapper-red {
      background: #FF5959;
    }
  }

  .pending-recruiters-table {
    .button_green, .button_red {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .td-overflow {
      padding: 0 10px;
      overflow: auto;

      @include tablet {
        max-width: 250px;
      }

      @include mobile {
        max-width: 150px;
      }
    }
  }

  .approved-recruiters-table {
    .td-overflow {
      padding: 0 10px;
      overflow: auto;

      @include tablet {
        max-width: 200px;
      }

      @include mobile {
        max-width: 125px;
      }
    }
  }

  .invite-recruiters .input_search,
  .invite-recruiters .button_search {
    @include tablet {
      max-width: 400px;
      width: 90vw !important;
      margin-bottom: 10px;
    }
  }

  .school-black-list {
    input {
      @include mobile {
        width: 90vw !important;
        max-width: 290px !important;
      }
    }

    .button_search {
      @include tablet {
        width: 79px !important;
      }

      @include mobile {
        margin-top: 10px;
        width: 90vw !important;
        max-width: 290px !important;
      }

    }
  }

  &_form-wrapper {
    .settings-subdomain-link {
      margin: 20px 0px;
      text-align: center;

      a {
        font-size: 16px;
        color: $green;
      }
    }

    .post-approval-settings-form {
      @include mobile {
        .radio-item {
          height: 100px;
        }
      }

      label {
        width: max-content;
        margin: 20px 0;

        .fake_label {
          max-width: 700px;
          width: 45vw;
        }
      }
    }

    .color-schema-settings-form_fields {
      display: flex;
      flex-wrap: wrap;

      .field {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .color-input {
        padding: 0;
        width: 100px;
      }
    }

    .field {
      position: relative;
      margin: 0 auto;

      input[type=text] {
        font-style: italic;
        margin-top: 15px;
        border-radius: 4px;
      }
    }

    .visibility-field {
      width: 320px;
    }
  }

  .sign-up-form {
    .field {
      align-self: center;
      max-width: 478px;
    }

    .edit-school-buttons .button {
      margin-top: 30px;
      width: 90vw;
    }

    .field,
    .search-select,
    .patch-school_input,
    .sign-up-school-page_input {
      max-width: 400px;
      width: 90vw;
    }

    @media (max-width: 1200px) and (min-width: 821px) {
      .field_flex .field_radio .field_nested_input {
        margin-left: -50% !important;
      }
    }

    .vacation {
      margin-left: 0;
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    margin-right: 3vw;
    font-size: 16px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.2px;
    text-align: left;

    &_link-settings {
      max-width: 330px;
      width: 22vw;
      height: 62px;
      text-align: left;
      background: $white;
      color: #9C9C9C;
      border: 1px solid $border-grey;
      padding: 0 25px;
      margin-bottom: 22px;
    }

    &_link-settings.active {
      color: $black;
      background: $white url(../../images/arrow-settings.png) no-repeat 90% 50%;
    }
  }

  .code-snippet {
    width: 100%;

    &_text {
      margin: 20px 0 30px;
    }

    #code-snippet-text {
      width: 100%;
      min-height: 150px;
    }

    .button {
      margin-top: 5px;
    }

    iframe {
      display: none;
    }
  }

  .application-use-form {
    .field {
      align-items: center;
    }
  }

  .notification-setting,
  .application-use-form {
    .settings-page_title {
      margin-bottom: 25px;
    }
    .field {
      label, input {
        margin: 10px;
      }
    }
  }
}

@include tablet {
  .settings-page.company-set-up {
    flex-direction: column;
    padding: 0;
    position: relative;

    .content {
      width: 100%;
      padding: 27px 15px 40px 15px;
    }

    .dropdown-menu {
      top: $mobile-header-height;
    }

    .nav.dropdown-menu {
      top: $dropdown-item-height;
    }

    .dropdown-menu_item, .nav_link-settings {
      max-width: 100vw;
      width: 100%;
      margin: 0;
      color: $black;
    }

    .nav_link-settings.is-chosen {
      color: $white;
      font-weight: 700;

      &.arrow-up {
        background: $green url(../../images/white-arrow-up.png) no-repeat 94% 50%;
      }

      &.arrow-down {
        background: $green url(../../images/white-arrow-down.png) no-repeat 94% 50%;
      }
    }

    .settings-page_buttons {
      flex-direction: column;
      margin-top: 40px;
    }

    .settings-page_buttons .button {
      margin-top: 0;
    }

    .button {
      width: 54vw;
    }

    .post-approval-settings-form {
      .settings-page_title {
        margin-bottom: 10px;
      }

      .field {
        width: 100%;
      }

      .fake_label {
        width: 80vw;
      }
    }

    .settings-page_search button {
      width: 100%;
    }

    .visibility-field,
    input[type=password] {
      width: 100%;
    }
  }
}
