.jobs-list-page {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;

  &_search-select {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &_checkbox-group {
    label {
      color: $black;
      font-size: 16px;
    }
  }

  &_select-group {
    display: flex;
    flex-direction: column;

    & > * {
      margin-top: 15px;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    background: $light-grey;
    padding: 15px 100px 25px 100px;
    min-height: 73.3vh;

    @include tablet() {
      padding: 15px 50px 25px 50px;
    }

    @media (max-width: 685px) {
      padding: unset;
      padding-bottom: 20px;
    }

    &_upper-section {
      display: flex;
      justify-content: flex-end;

      .hiring {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        span {
          font-size: 16px;
        }

        @media (max-width: 1050px) {
          margin-bottom: 15px;
        }
        @media (max-width: 687px) {
          margin: 5px 15px;
        }

      }

      .button_post-job {
        border: 1px solid $border-grey;
        height: 43px;
        font-weight: 600;
        margin: 0 15px;
        padding: 0 15px;
        position: relative;

        @include desktop() {
          top: -2px;
        }

        @include laptop() {
          margin-right: 0;
        }
      }

      .button_view {
        width: 43px;
        border: 1px solid $border-grey;
        height: 43px;
        font-weight: 600;
      }

      .button_view_rows {
        background: $white url(../../../images/job-posting-rows.svg) no-repeat scroll 50%;
        border-right: none;
      }

      .button_view_rows.active {
        background: $white url(../../../images/job-posting-row-active.svg) no-repeat scroll 50%;
      }

      .button_view_table {
        background: $white url(../../../images/job-posting-table.svg) no-repeat scroll 50%;
        border-left: none;
      }

      .button_view_table.active {
        background: $white url(../../../images/job-posting-table-active.svg) no-repeat scroll 50%;
      }
    }

    &_middle-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      @media (max-width: 1050px) {
        flex-direction: column;
      }

      .cannot-open-error {
        width: 100%;
        text-align: center;
      }

      .sidebar {
        display: flex;
        flex-direction: column;

        &_block {
          display: flex;
          flex-direction: column;
          margin-bottom: 19px;
          background-color: $white;
          width: 348px;
          border: 1px solid $border-grey;
          box-sizing: border-box;
          transition: all 1s ease-out;

          @media (max-width: 1050px) {
            width: unset;
          }

          &_title {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.2px;
            font-weight: 600;
            margin-bottom: 15px;
          }

          &_button {
            width: 100%;
            height: 42px;
            font-weight: 700;
          }

          &_apply-button {
            background: #282C35;
            color: $white;
            border: 1px solid #282C35;
          }
        }

        .jobs-filters {
          display: flex;
          flex-direction: column;
          align-content: space-between;
          padding: 15px;
          height: auto; 
          transition: all 1s ease-out;
          overflow: hidden;

          @media (max-width: 1050px) {
            display: block;
          }

          @media (max-width: 685px) {
            padding: 10px 5px;

            .board-type-buttons {
              margin-left: -5px;
              margin-right: -5px;
            }
          }

          .board-type-buttons {
            margin-bottom: 0;

            @media (max-width: 685px) {
              margin-bottom: 10px;
            }

            .sidebar_block_button {
              margin-bottom: 15px;
              background: #282C35;
              color: $white;
              border: 1px solid #282C35;

              @media (max-width: 685px) {
                margin-bottom: 10px;
                border: unset;
              }
            }

            #filter {
              margin-bottom: 0px;
            }

            .board-type-button {
              width: 50%;
              height: 43px;
              margin-bottom: 20px;
              border: 1px solid $green;
              font-weight: bold;
              background-color: $white;

              &:last-child {
                border-left: none;
              }

              &:disabled {
                cursor: not-allowed;
              }

              @media (max-width: 685px) {
                margin-bottom: unset;
              }
            }

            .board-type-button-active {
              background-color: $transparent-green-2;
            }
          }

          .companies_list {
            @media (max-width: 1050px) {
              display: inline-block;
              width: 50%;
            }

            @include mobile() {
              display: initial;
              width: 100%;
            }
          }

          .search_input,
          .filters_input {
            width: 100%;
            padding-left: 0;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            font-size: 14px;
            font-style: italic;
          }

          .search {
            margin-bottom: 15px;

            @media (max-width: 1050px) {
              display: inline-block;
              width: 50%;
            }

            @include mobile() {
              display: initial;
              width: 100%;
            }

            .sidebar_block_title {
              margin: 15px 0;
            }

            .react-datepicker__close-icon:after{
              background-color: #ff6e6e;
              color: $white;
              padding: 5px;
              font-size: 8px;
              content: "X";
            }

            .location-items {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              margin: 20px 0 24px 0;

              .location-item {
                background-color: $transparent-green;
                padding: 4px 14px;
                color: $green;
                letter-spacing: 0.3px;
                font-size: 14px;
                font-weight: 700;
                height: 30px;
                margin-bottom: 0;
              }
            }
          }
          
          .filters {
            @media (max-width: 1050px) {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }

            @include mobile() {
              display: flex;
              flex-direction: column;
            }

            .field {
              margin-top: 20px;
            }

            .positions {
              @media (max-width: 1050px) {
                grid-column-end: 3;
                grid-column-start: 1;

                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-bottom: 0;

                p {
                  grid-column-start: 1;
                  grid-column-end: 3;
                }
              }

              @include mobile() {
                display: flex;
                flex-direction: column;
              }
            }

            label {
              color: $black;
              font-size: 16px;
            }

            .switcher {
              margin: 0 auto;
              margin-top: 15px;
              cursor: pointer;
              grid-column-end: 3;
              grid-column-start: 1;

            } 
          }
        }

        .current-posting-page {
          .search {
            @media (max-width: 1050px) {
              display: grid ;
              grid-template-columns: 1fr 1fr;
              width: 100%;

              > div {
                padding: 5px;
              }
            }

            @media (max-width: 450px) {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }

      .mainbar {
        width: 100%;
      }
    }
  }

  hr {
    margin: 0;
  }

  .pay-for-unpaid {
    text-align: right;
    margin-right: -30px;

    @media (max-width: 1050px) {
      margin-right: unset;
      margin-bottom: 10px;
    }

    @media (max-width: 685px) {
      margin: 10px 5px;
    }

    @media (max-width: 450px) {
      text-align: center;
    }

    b {
      color: $red;
    }

    button {
      color: $white;
      background-color: $green;

      height: 50px;
      width: 220px;
      border: none;

      @media (max-width: 450px) {
        width: 100%;
      }
    }

    button:disabled {
      cursor: not-allowed;
      background-color: $grey;
    }
  }
}
