.new-job-wrapper {
  padding: 20px 100px;

  .header-chapter {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .inner-chapter {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .form-control {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
  }
}

.job-boards-search {
  display: flex;
  flex-direction: column;

  @include tablet {
    align-items: center;
  }

  .checkbox-item,
  .radio-item {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .show-only-schools {
    margin: 10px 0;
  }

  .added-schools {
    margin-top: 20px;
    max-width: 355px;
    width: 90vw;

    &_upper-section {
      justify-content: space-between;

      span, .clear-all-btn {
        background: none;
        border: none;
        color: $grey;
        font-size: 14px;
      }

      .clear-all-btn:hover {
        color: $red;
      }
    }

    &_frame {
      border: 1px solid $grey;
      height: 180px;
      overflow-y: scroll;
      margin-top: 5px;

      &_school {
        width: 100%;
        padding: 10px 20px;
        align-items: center;
        background-color: #737B7D0D;
      }

      &_school:hover {
        background-color: #737B7D4D;
      }

      &_remove-btn {
        background: url(../../../images/multiply.png) no-repeat;
        width: 10px;
        height: 10px;
        border: none;
        background-size: 10px;
        margin-right: 10px;
      }
    }
  }

  label {
    align-items: center;
  }
}

.school-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  .job-boards-search {
    display: flex;
    align-items: center;
  }

  .label {
    margin-bottom: 10px;
  }

  .button_add-schools, .button_remove {
    border: none;
    color: $white;
    background-color: $green;
    padding: 10px 20px;
    margin: 10px 0;
    align-self: center;
  }

  .button_remove:disabled {
    background-color: $transparent-green-2;
  }

  &_table {
    border-collapse: collapse;
    margin: 20px 0;
    width: 90vw;
    max-width: 686px;

    thead tr {
      border: none;
    }

    tbody tr {
      border-top: 1px $border-grey solid;
      border-bottom: 1px $border-grey solid;
      height: 100px;
    }

    th, td {
      width: 120px;
      text-align: center;

      .checkbox-item {
        align-items: center;
        justify-content: start;

        @include tablet {
          justify-content: center;
        }
      }
    }
  
    th:not(:first-child), td:not(:first-child) {
      @include tablet {
        width: 60px;
      }
  
      @include mobile {
        width: 30px;
      }
    }

    @include mobile {
      th {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      }

      p, div {
        word-break: break-word;
        flex: 1;
      }

      tr {
        display: flex;
        flex-direction: column;
        height: min-content !important;
      }

      td {
        display: flex;
        justify-content: space-between;
        width: 100% !important;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          .fake_button-checkbox {
            margin: 0 !important;
          }
        }
      }

      td:nth-child(2) {
        justify-content: center;

        button {
          width: 90px;
          margin: 10px 5px;
        }
      }
    }
  }

  &_view-table {
    td {
      text-align: left;
      width: 30vw;
      margin: 10px;
    }

    td:nth-child(2) {
      justify-content: left;
    }

    tr {
      @include mobile {
        flex-direction: row;
      }
    }

    .button_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200%;
      margin-top: 20px;

      @include mobile {
        width: 100%;
      }
    }
  }

  

  .checkbox-item {
    @include tablet {
      justify-content: center;
    }
  }

  input[type=checkbox]:disabled + label .fake_button-checkbox.checkbox {
    border: 2px solid $grey;
    background: $white;
  }

  &_avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.total-amount {
  text-align: center;

  b {
    color: $green;
  }
}