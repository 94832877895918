.application-form {
  .nested_form {
    margin: 0;
  }

  .inputs-container,
  .sign-up-form {
    max-width: 1000px;
    width: 90vw;

    .input {
      width: 100%;

      @include tablet {
        width: 90vw;
      }
    }

    .field {
      display: flex;
      flex-direction: column;
      align-items: center;

      textarea {
        max-width: 450px;
      }

      .field_wrapper {
        width: 90vw;

        .react-datepicker-wrapper {
          max-width: 450px;
        }

        .field_radio {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .radio-items-container {
            width: min-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .radio-boolean {
            align-items: center;
          }

          // employmentHistoryInfo.*.isEmployed
          .I-do-not-have-any-additional-employment-or-unemployment-to-report {
            .fake_label {
              height: 45px;
              width: 300px;
            }
          }
        }
      }

      .boolean-checkbox p {
        margin-bottom: 10px;
        max-width: 600px;
        width: 80vw;
        text-align: justify;

        &.last {
          text-align: center;
        }
      }

      .boolean-checkbox,
      .boolean-checkbox label,
      .radio-item label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      label {
        display: block;
        text-align: left;
        max-width: 450px;
        width: 90vw;
        margin: 0;
      }
    }
  }

  .progress-bar {
    max-width: 450px;
    margin-bottom: 20px;
  }

  .error-message {
    padding: 0;
  }

  .button_add, .button_close {
    background: $green;
    color: $white;
    font-weight: 700;
    border: none;
    min-height: 42px;
    min-width: 100px;
    margin-bottom: 10px;
    padding: 0 20px;
  }

  .dropdown {
    .dropdown_input {
      max-width: 450px;
      width: 90vw;
    }

    .dropdown_span {
      display: none;
    }
  }

  .boolean-checkbox {
    label {
      flex-direction: column;
      align-items: center;
      color: #373F41;

      .fake_button-checkbox {
        margin: 0 10px;
      }
    }
  }
}

.button-wrap {
  flex-wrap: wrap;
}
