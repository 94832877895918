.claim-school-page {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 50px 50px;

    .content_col1 {
      max-width: 460px;
      display: flex;
      flex-direction: column;
      @include desktop {
        margin-right: 10vw;
      }
    }

    @media (max-width: 450px) {
      padding: 80px 0;
    }
  }

  .button.button_sign-up {
    width: 416px;

    @include mobile {
      width: 200px;
    }
  }
  .claim-school-form {
    .container-horizontal {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
  
      img {
        margin: 0 50px;
      }
    }

    .school-list_table {
      tr {
        @include mobile {
          flex-direction: row;
        }
      }

      .pop-up_btn-green {
        padding: 10px 20px;
        margin: 10px 0;
        align-self: center;
      }
    }
  }

  .container-for-inputs {
    align-items: center;
    justify-content: center;

    @include laptop {
      margin-left: 50px;
      margin-right: 50px;
    }

    @media (max-width: 500px) {
      margin-left: unset;
      margin-right: unset;
    }

    .claim-school-page_input,
    .field {
      width: 443px;
    
      @media (max-width: 500px) {
        width: 100%;

        &_wrapper {
          width: 100%;
        }
      }
    }

    .claim-school-page_input {
      display: block;
      margin: 9px 0;
      height: 34px;
      background: $input-bg;
      mix-blend-mode: normal;
      border: 1px solid $green;
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 8px;
      font-size: 12px;
      font-style: italic;
      color: $black;
    }
  }

  .school-list {
    .text {
      margin-bottom: 20px;
    }

    th, td {
      padding: 10px;
    }

    .th-phone {
      width: 140px;
    }
  }
}
