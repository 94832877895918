.home-page {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_title {
      font-size: 55px;
      text-align: center;
    }

    &_text {
      font-size: 16px;
      color:
      $medium-grey;
      text-align: center;

      &_claim {
        font-size: 12px;
      }
    }

    &-claim-school {
      height: 560px;
      background-color: $light-grey;

      &_title {
        margin-bottom: 32px;
      }

      &_text {
        width: 730px;
      }

      &_search {
        display: flex;
        margin-top: 64px;
        margin-bottom: 13px;
      }
    }

    &-grow-with-truckboards {
      padding-top: 63px;
      padding-bottom: 70px;

      &_head {

        &_title {
          max-width: 920px;
        }
  
        &_text {
          margin: 24px 0;
        }
      }
    }
    &-grow-with-truckboards.recruiter-logged {
      background-color: $light-grey;
    }

    &-look-at-job-board {
      position: relative;
      background-color: $green;
      height: 523px;

      h1:nth-child(n),
      p:nth-child(n),
      img:nth-child(n),
      button:nth-child(n) {
        position: absolute;
      }

      &_title {
        text-align: left;
        top: 115px;
        left: 415px;
        width: 490px;
        color: $white;
      }

      &_text {
        text-align: left;
        top: 130px;
        left: 80px;
        width: 300px;
        font-size: 38px;
        font-weight: 700;
        color: $white;
      }

      &_link {
        text-decoration: none;
        
        &_button {
          background-color: $dark-grey;
          top: 297px;
          left: 415px;
          height: 58px;
          max-width: 350px;
          width: 50vw;
          color: $white;
          font-size: 20px;
          font-weight: 400;
          border: none;
          z-index: 2;
        }
      }

      &_glass {
        bottom: 0;
        right: 0;
        max-width: 100%;
      }

      &_rectangle {
        top: 119px;
        left: 361px;
      }

      &_bg {
        left: 55%;
      }
    }

    &-statistic {
      background: $light-grey;
      padding: 50px 0 100px 0;
      
      &_text {
        max-width: 60%;
        margin: 0 auto;
        margin-bottom: 50px;
      }
    }

    &-find-new-job {
      position: relative;
      height: 362px;
      background: $white;

      &_bg {
        position: absolute;
        margin: 0 auto;
        width: 976px;
        height: 322px;
        background-size: 976px;
        border-radius: 14px;
      }

      .truck {
        z-index: 1;
        background: url(../../images/truck-driver.png) no-repeat scroll;
        filter: drop-shadow(0px 4px 4px #00000040);
      }

      .gradient {
        z-index: 0;
        background: linear-gradient(265.05deg, #E73635 4%, #6AD5FF 87.25%);

      }

      &_content {
        z-index: 2;

        .title {
          font-size: 40px;
          color: $white;
        }

        .text {
          margin-top: 14px;
          margin-bottom: 34px;
          max-width: 551px;
          color: $white;
        }
      }

      &_button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        z-index: 2;

        img {
          margin-left: 15px;
        }
      }
    }
  }

  .button {
    border: none;
    font-weight: 700;

    &_link {
      text-decoration: none;
      color: $white;
    }

    &_green {
      margin: 0;
      border: none;
      background-color: $green;
      color: $white;
      height: 48px;
    }
  }

  .cards {
    display: flex;

    &_card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 350px;
      height: 204px;
      margin: 51px 30px 30px 0;

      &_img {
        margin-bottom: 20px;
        height: 32px;
      }

      &_title {
        font-size: 18px;
        font-weight: 700;
      }

      &_text {
        text-align: center;
      }

      &_button {
        background-color: $white;
        color: $green;
      }
    }
  }
}

.statistics {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: $light-grey;

  &_block {
    width: 25%;
    padding: 5px 5px 20px 5px;
    border: 1px solid $green;
    background-color: $white;
    text-align: center;

    &_head-number {
      font-size: 50px;
      font-weight: 900;
      color: $green;
      font-family: "Mulish", sans-serif;
    }

    &_description {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      font-family: "Mulish", sans-serif;
    }
  }
}

@include laptop() {
  .home-page {
    
    .section {
    
      &-grow-with-truckboards {

        &_head {

          &_title {
            font-size: 32px;
          }
    
          &_text {
            font-size: 16px;
          }
        }

        .cards {
          flex-wrap: wrap;
          justify-content: center;

          &_card {
            height: auto;
            margin: 0;
            padding: 0 40px;
            margin-bottom: 40px;
            line-height: 18px;
            width: 400px;

            &_img {
              height: 35px;
            }
      
            &_title {
              margin-bottom: 10px;
              font-size: 20px;
              text-align: center;
            }

            &_text {
              font-size: 16px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &-look-at-job-board {
        height: 500px;
        overflow: hidden;

        &_link {
          &_button {
            max-width: 260px;
            top: 215px;
            left: 300px;
          }
        }

        &_title {
          top: 15%;
          left: 300px;
          font-size: 42px;
          max-width: 400px;
        }

        &_text {
          top: 80px;
          left: 60px;
          width: 500px;
          width: 170px;
          font-size: 36px;
          line-height: normal;
        }

        &_rectangle {
          top: 75px;
          left: 250px;
        }

        &_bg {
          width: 500px;
          left: 55%;
          bottom: 1%;
        }
      }

      &-statistic {
        padding: 50px 0;

        &_title {
          font-size: 50px;
          font-weight: 500;
        }
        
        &_text {
          max-width: 80%;
          margin-bottom: 50px;
          font-size: 16px;
          line-height: normal;
          color: $black;
        }

        .statistics {
          flex-direction: column;

          &_block {
            width: 50%;
            margin-bottom: 30px;
            padding-top: 10px;
            padding-bottom: 25px;

            &_head-number {
              font-size: 40px;
            }

            &_description {
              width: 80%;
              margin: 0 auto;
              font-size: 18px;
              line-height: 18px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &-find-new-job {

        &_bg {
          width: 70%;
        }

        .truck {
          background-position: center;
        }

        &_content {
          padding: 0 20%;
        }        
      }
    }
  }
}

@include tablet() {
  .home-page {
    
    .section {

      &-claim-school {
        height: auto;
        padding: 60px 20px;
  
        &_text {
          width: 100%;
        }
  
        &_search {
          width: 100%;
          margin-top: 0;
        }
      }

      &-grow-with-truckboards {
        padding: 63px 20px 70px 20px;
      }

      &-look-at-job-board {
        height: 700px;

        &_title {
          top: 17%;
          text-align: center;
          width: 100vw;
          left: unset;
        }

        &_link {
          &_button {
            top: 280px;
            left: 25%;
            max-width: 400px;
          }
        }

        &_glass {
          right: -5%;
          max-width: 1100px;
        }

        &_text {
          top: 5%;
          left: 10%;
          width: 80vw;
          text-align: center;
        }

        &_rectangle {
          display: none;
        }
      }

      &-find-new-job {

        &_bg {
          width: 90%;
        }

        &_content {
          margin-bottom: 20px;
  
          .title {
            font-size: 28px;
            margin-bottom: 10px;
          }
  
          .text {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@include mobile() {
  .home-page {
    
    .section {

      &_text {
  
        &_claim {
          width: 70%;
          font-size: 12px;
          line-height: 15px;
        }
      }

      &-claim-school {
        padding: 60px 0;
  
        &_title {
          margin-bottom: 15px;
          font-size: 34px;
        }
  
        &_text {
          width: 80%;
          font-size: 13px;
          line-height: 15px;
        }
  
        &_search {
          width: 100%;
          margin-top: 25px;
          margin-bottom: 10px;
          padding: 0 10px;
        }
      }

      &-grow-with-truckboards {
        padding-top: 30px;
        padding-bottom: 70px;

        &_head {
          margin-bottom: 50px;

          &_title {
            max-width: 415px;
            padding: 10px 35px;
            font-size: 34px;
            text-align: center;
            line-height: normal;
          }
    
          &_text {
            margin: 0;
            padding: 0 35px;
            font-size: 12px;
            line-height: 15px;
          }
        }

        .cards {

          &_card {
            line-height: 18px;

            &_img {
              height: 30px;
            }
      
            &_title {
              margin-bottom: 8px;
              font-size: 16px;
            }

            &_text {
              font-size: 14px;
            }
          }
        }
      }

      &-look-at-job-board {
        height: 600px;

        &_link {
          &_button {
            top: 250px;
            width: 90vw;
            height: 50px;
            left: 5%;
          }
        }

        &_title {
          top: 18%;
          left: 8%;
          width: 85%;
          font-size: 34px;
        }

        &_text {
          font-size: 30px;
        }

        &_glass {
          right: -12%;
          bottom: 0;
          max-width: 250%;
        }

        &_play-text {
          top: 15%;
          width: 15%;
        }

        &_bg {
          left: 15%;
          bottom: 9%;
          max-width: 90%;
        }
      }

      &-statistic {

        &_title {
          font-size: 34px;
        }
        
        &_text {
          margin-bottom: 30px;
          font-size: 11px;
        }

        .statistics {

          &_block {
            width: 70%;
          }
        }
      }

      &-find-new-job {
        height: 260px;

        &_bg {
          width: 90%;
          height: 90%;
        }

        &_content {
          margin-bottom: 30px;
          padding: 0 5%;
  
          .title {
            font-size: 20px;
            margin-bottom: 10px;
          }
  
          .text {
            margin: 0 auto;
            max-width: 80%;
            font-size: 10px;
            color: $white;
          }
        }

        &_button {
          height: 35px;
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }
  }
}
