.sign-up-page {
  .content_col1 {
    width: unset !important;

    @include laptop {
      width: unset !important;
    }
  }

  .field_wrapper {
    width: 100%;
  }

  .search-select {
    padding: 0 5px;

    @include laptop {
      padding: unset;
    }
  }

}

.sign-up-form .edit-recruiter-buttons {
  justify-content: space-between;

  button {
    flex-grow: initial !important;
  }
}

.payment-form {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.payment-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 30px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #54BE73;
  outline: 0;
  border-radius: 4px;

  @include mobile {
    padding: unset;
  }

  .billing-address {
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
  
    p {
      border-bottom: 1px solid #54BE73;
    }
  }

  button {
    font-weight: 700;
    background-color: $status-rejected;
    border: none;
    color: $white;
    padding: 10px 20px;
    margin: 10px 0;
  }

  .rccs {
    @include mobile {
      transform: scale(0.8)
    }
  }
}

.payment-form-button {
  font-weight: 700;
  width: 100%;
  height: 42px;
  padding: 0 10px;
  background: $green;
  color: $white;
  border: none;
  margin: 10px 0px;
}

.payment-replace-card-hint {
  font-size: 10px;
}

.payment-card-header {
  text-align: left;
}
