.schools-list-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  padding: 0 150px 100px 150px;
  background-color: $light-grey;

  @media (max-width: 1400px) {
    padding: 0 70px 100px 70px;
  }

  @include tablet() {
    padding: 0 50px 50px 50px;
  }

  @media (max-width: 685px) {
    padding: unset;
    padding-bottom: 20px;
  }

  .title {
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    color: $green;
    margin-top: 68px;
    margin-bottom: 7px;
    text-align: center;
  }

  .title-description {
    margin-bottom: 25px;
    color: $medium-grey;
    font-size: 12px;
    text-align: center;
  }

  .switch-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

    .switch-button {
      position: relative;
      display: flex;
      align-items: center;
      width: 18%;
      height: 40px;
      padding-left: 50px;
      color: $black;
      border: 1px solid lighten($color: $grey, $amount: 30);
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      background-color: $white;

      @include tablet() {
        width: 25%;
      }

      @media (max-width: 450px) {
        width: 40%;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      &_text:before {
        position: absolute;
        top: 17%;
        left: 5%;
      }

      &_text-claimed:before {
        content: url('../../../images/tick.png');
      }

      &_text-pending:before {
        content: url('../../../images/tick.png');
      }

      &_text-decline:before {
        content: url('../../../images/cross.png');
      }

      &_text-approve:before {
        content: url('../../../images/tick.png');
      }

      &_text-unclaimed:before {
        content: url('../../../images/cross.png');
      }
    }

    .switch-button-active {
      border: 1px solid $green;
      box-shadow: 0 0 4px $green;
    }
  }

  .list-of-schools {
    width: 100%;
    margin-bottom: 76px;

    &_found-schools-title {
      margin-bottom: 10px;
    }

    .school-block {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      margin: 0 auto 12px;
      padding: 15px;
      background-color: $white;
      border: 1px solid $border-grey;
      word-break: break-word;
      flex-wrap: wrap;

      &:last-child {
        margin-bottom: 0;
      }

      &_avatar {
        display: flex;
        width: 30%;
        height: 100%;
        overflow: hidden;

        @include laptop() {
          width: unset;
        }

        @media (max-width: 685px) {
          align-self: flex-start;
        }

        &_image {
          width: 70px;
          height: 70px;
          margin-right: 20px;
          border-radius: 50%;
          flex-shrink: 0;
        }

        &_image.no-avatar {
          background-color: $dark-grey;
        }

        &_name {
          line-height: normal;
          font-weight: bold;
        }
      }

      &_network-contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 65%;

        @include laptop() {
          width: 80%;
          padding-left: 0px;
        }

        @media (max-width: 685px) {
          width: 100%;
        }

        &_contact {
          margin-bottom: 4px;

          @include mobile() {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .contact-header {
            min-width: 80px;
            margin-right: 5px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &_buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        align-self: flex-end;

        margin: 0 -10px;
        & > * {
          margin: 5px;
        }

        height: 100%;

        @media (max-width: 685px) {
          flex-direction: row;
          width: 100%;
        }

        &_link {
          text-decoration: none;

          &_button-view-details {
            height: 40px;
            border: 1px solid $green;
            font-weight: 700;
            font-size: 14px;
            text-align: center;
            color: $green;
            background-color: $white;

            &:hover {
              box-shadow: 0 0 2px $green;
            }
          }
        }
      }
    }
  }

  .schools-list-page-container {
    display: flex;
    width: 100%;

    @media (max-width: 1050px) {
      flex-direction: column;
    }

    .sidebar {
      display: flex;
      flex-direction: column;
      width: 348px;
      box-sizing: border-box;
      margin-right: 20px;

      @media (max-width: 1050px) {
        width: unset;
        margin-right: unset;
      }

      &_content {
        width: 100%;
        height: auto;
        padding: 15px;
        border: 1px solid $border-grey;
        border-bottom: none;
        background-color: $white;

        @media (max-width: 1050px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 450px) {
          display: flex;
          flex-direction: column;
        }

        &_filter {
          padding: 5px;
        }

        &_title {
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.2px;
          font-weight: 600;
        }

        .state-select-filter {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &_state-select {
          margin-top: 10px;

          .css-10vcfyg-control,
          .css-1luspcw-control,
          .css-wwp8ib-menu {
            @media (max-width: 1050px) {
              width: 100%;
            }
          }
        }

        &_input {
          width: 100%;
          padding-left: 0;
          margin: 22px 0 33px;
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-style: italic;
        }
      }

      &_buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 15px 15px 15px;
        border: 1px solid $border-grey;
        border-top: none;
        background-color: $white;

        &_button {
          width: 100%;
          padding: 10px 0;
          background: #282C35;
          color: $white;
          border: 1px solid #282C35;
        }
      }
    }

    .school-block {
      width: 100%;
    }
  }

  .load-button {
    width: 20%;
    height: 40px;
    border: 1px solid $green;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: $green;
    background-color: $white;

    &:hover {
      box-shadow: 0 0 2px $green;
    }
  }

  &_results {
    flex: 1;
  }
}

.not-extended {
  div,
  p {
    display: none !important;
  }
}