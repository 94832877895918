.log-in-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 20px;
  
  .field {
    width: 100% !important;
  }

  .input {
    padding: 7px;
    margin-bottom: 20px;
    border: 1px solid $grey;
  }

  .btn-log-in {
    width: 100%;
    border: none;
    padding: 5px;
    font-size: 20px;
    font-weight: 700;
    background-color: $green;
    color: $white;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      background-color: lighten($dark-green, 10%);;
    }
  }

  a {
    margin-top: 5px;
    color: $green;
    text-decoration: none;
  }
}
