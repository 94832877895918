.companies-for-admin-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  padding: 0 150px 100px 150px;
  background-color: $light-grey;
  letter-spacing: 0.2px;

  @media (max-width: 1400px) {
    padding: 0 70px 100px 70px;
  }

  @include tablet() {
    padding: 0 50px 50px 50px;
  }

  @media (max-width: 685px) {
    padding: unset;
    padding-bottom: 20px;
  }

  &_title {
    margin: 57px 0 58px 0;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: $green;
    text-align: center;
  }

  &_container {
    display: flex;
    width: 100%;

    @media (max-width: 1050px) {
      flex-direction: column;
    }
  }

  &_sidebar {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    width: 348px;
    margin-right: 27px;
    margin-bottom: 20px;
    padding: 35px;
    border: 1px solid $border-grey;
    background-color: $white;

    @media (max-width: 1050px) {
      align-self: unset;
      width: unset;
      margin-right: unset;
    }

    &_content {
      display: flex;
      flex-direction: column;

      &_filter {
        display: flex;
        flex-direction: column;
      }
    
      &_title {
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
      }
    
      &_input {
        margin: 22px 0 38px;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        font-size: 14px;
        font-style: italic;
      }
    }

    &_buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &_button {
        padding: 12px 0;
        border: none;
        font-size: 14px;
        background: $dark-grey;
        color: $white;
      }
    }
  }

  &_results {
    flex: 1;
    display: flex;
    flex-direction: column;

    &_export-button {
      margin: unset;
      margin-bottom: 12px;
      margin-left: auto;
      padding: 7px 38px;
      border: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      background-color: $white;
      cursor: pointer;
    }
  }
}
