.vacation {
  margin-left: -60%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &_main {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: italic;
    width: fit-content;

    .titles {
      display: flex;
      flex-direction: row;
      .title {
        text-align: center;
        margin-bottom: 10px;
        font-style: normal;
      }
    }

    label {
      display: flex;
      width: fit-content;

      .number-dropdown_span {
        top: -11px;
        left: 5px;
      }

      span {
        margin: 3px 10px;
      }

      .button_close {
        align-self: center;
        border: none;
        width: 10px;
        height: 10px;
        background: url(../../images/btn_close.png) no-repeat;

        &:disabled {
          background: url(../../images/btn_close-grey.png) no-repeat;
        }
      }
    }
    .button_add {
      align-self: flex-end;
      width: 10px;
      height: 10px;
      margin-bottom: 5px;
      padding: 0;
      border: none;
      background: url(../../images/btn_add.png) no-repeat;

      &:disabled {
        background: url(../../images/btn_add-grey.png) no-repeat;
      }
    }
  }

  &_row_dropdown-wrapper {
    display: flex;
  }
}

@include laptop {
  .vacation {
    margin-left: 0;

    .titles {
      align-items: center;
    }

    &_row {
      align-items: center;

      &_dropdown-wrapper.container-vertical {
        flex-direction: column;
      }
      &_dropdown-wrapper.container-vertical-reverse {
        flex-direction: column-reverse;
      }
    }
  }
}
