.jobs-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 30px;

  @media (max-width: 1050px) {
    margin-left: unset;
  }

  &_promoted-jobs_title,
  &_found-jobs_title {
    margin: 16px 0 16px 15px;
  }

  &_promoted-jobs_title {
    margin: 0 0 16px 15px;
    font-weight: 600;
  }

  &_promoted-jobs_content,
  &_found-jobs_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  &_button-enter-password-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .jobs-table_button-enter-password {
      width: 50%;
      height: 42px;
      border: none;
      background: $green;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
  
      @include tablet {
        width: 100%;
      }
    }
  }
}
