.edit-company .content .company-edit-form,
.edit-job .content .job-edit-form,
.settings-page .company-edit-form {
  &_down-section {
    width: 100%;

    .buttons {
      width: 55%;
      flex-direction: column;

      @include tablet {
        width: 80%;
        flex-direction: column !important;
      }

      &_control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        order: 1;

        margin-bottom: 19px;

        .button_control {
          max-width: 235px;
        }
      }

      &_pages {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        order: 2;
        margin: -8px;
        > * {
          margin: 4px;
        }

        @include mobile {
          width: 80%;
        }

        .button {
          width: 42px;
          height: 42px;
          background-color: $green;
          color: $white;
          font-size: 14px;
          letter-spacing: 0.3px;
          font-weight: 700;
          border: none;
          margin-top: 0;
        }

        .active-button {
          background-color: $transparent-green-2;
        }

        .button_red {
          background-color: $red;
        }

        .button_red.active-button {
          background-color: $transparent-red;
        }
      }
    }
  }
  .company-field-no-spaces {
    .field_wrapper {
      width: 100%;
    }
  }
}
