.forgot-password {
  padding: 5%;

  h1 {
    color: $green;
    font-size: 46px;
    margin-bottom: 70px;
  }

  .field {
    align-self: unset;
  }
}