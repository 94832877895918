.job_view {
  background: $bg-1;
  padding: 50px 10%;
  font-size: 16px;

  .job-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    &_main-column,
    &_side-column {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    &_main-column {
      width: 60%;

      .main-info {
        display: flex;
        justify-content: space-between;
        padding: 21px 20px 20px 31px;

        .left-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .job-card_img,
          .job-card_img img {
            border-radius: 50%;
            margin-right: 25px;
          }

          .job-card_img.no-avatar {
            background: $grey;
          }

          .job-card_img,
          .job-card_img img {
            height: 62px;
            width: 62px;
          }

          .position {
            font-size: 30px;
            font-weight: 700;
          }

          .company-name {
            font-size: 18px;
            text-decoration: underline;
          }

          .location {
            background: url(../../../images/location-grey.png) no-repeat scroll 0;
            font-weight: 600;
            padding-left: 20px;
          }
        }

        .right-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;

          .date {
            padding-left: 15px;
            background: url(../../../images/date.png) no-repeat scroll 0 6px;
            margin: 0;
          }
        }

        a {
          color: $black;
        }
      }

      .details,
      .company-details {
        display: flex;
        flex-direction: column;
        padding: 40px 54px 60px 42px;

        h2 {
          text-align: center;
        }
      }

      .company-details {
        &_vacation {
          display: flex;
          align-items: center;

          &_index {
            font-weight: 600;
            font-size: 16px;
          }

          &_values {
            margin-top: 10px;
            margin-left: 10px;
            border-left: 2px solid $black;
            padding: 0 10px;
          }
        }
      }
    }

    &_side-column {
      width: 35%;

      .buttons {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 31px;

        .button {
          padding: 5px;
          border: 1px solid $green;
          
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }

        .button_apply,
        .button_reported {
          background-color: $green;
          color: $white;
          text-align: center;
          text-decoration: none;
        }

        .button-to-school-board {
          text-align: center;
          text-decoration: none;
        }

        .button_more-jobs {
          background-color: $white;
          color: $green;
        }
      }

      .job-type {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 36px 30px;

        .wages {
          color: $green;
          font-size: 24px;
        }

        .full-time {
          padding-left: 15px;
          background: url(../../../images/time.png) no-repeat scroll 0 7px;
        }

        .types-of-equipment {
          display: flex;
          flex-wrap: wrap;
          gap: 13px;

          .type-of-equipment {
            background-color: $transparent-green;
            color: $green;
            font-weight: 700;
            padding: 5px 13px;
          }
        }
      }

      .share {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        &_header {
          margin-bottom: 20px;
        }

        &_icons {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 15px;
          margin-bottom: 15px;
        }

        &_copy-job-link {
          position: relative;

          &_button {
            padding: 0;
            border: none;
            background-color: $white;
          }

          &_succeed {
            position: absolute;
            left: -8px;
            bottom: -35px;
            padding: 3px;
            border: 1px solid $grey;
            border-radius: 2px;
            color: $white;
            background-color: $grey;
          }

          &_succeed-hide {
            display: none;
          }
        }
      }
    }

    .job-container_section {
      display: flex;
      background: white;
      width: 100%;
      margin-bottom: 10px;

      &_row {
        .popper-container {
          margin: 0 2px;
        }
        
        i:first-of-type {
          margin-left: 5px;
        }

        i {
          word-break: break-word;
        }
      }
    }

    .table-row {
      display: flex;

      & > * {
        width: 50%;
      }
    }

    .border {
      border-top: 1px dashed #a4a4a4;
    }
  }
}

@media (max-width: 1100px) {
  .job_view {
    padding: 50px 5%;
  
    .job-container {
      &_main-column {
        .main-info {

          .left-container {
            margin-bottom: 5px;

            .position {
              font-size: 24px;
            }
          }
        }
      }

      &_side-column {
        .share {
          &_header {
            margin-bottom: 15px;
            text-align: center;
          }
  
          &_icons {
            grid-template-columns: 1fr 1fr;
            grid-gap: 15px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@include tablet {
  .job_view {
    padding: 20px 0;
    
    .job-container {
      display: flex;
      flex-direction: column;
  
      &_main-column {
        width: 100%;
        margin-bottom: 20px;

        .main-info {
          flex-direction: column;
          padding: 21px 0 0 0;

          &_mobile-buttons {
            flex-direction: column;
  
            .button {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              text-decoration: none;

              &:nth-child(even) {
                background-color: $white !important;
                color: $black !important;
              }
            }
  
          }

          .left-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            text-align: center;

            .img-wrapper {
              margin-right: 10px;

              .img {
                height: 90px;
              }
            }
    
            .position {
              font-size: 24px;
            }

            .position-and-name {
              display: flex;
              flex-direction: column-reverse;
            }
          }

          .right-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 0 20px;

            @include mobile {
              flex-direction: column;

              .container-vertical {
                align-self: flex-start;
              }
            }
            
            .location {
              background: url(../../../images/location-grey.png) no-repeat scroll 0;
              font-weight: 600;
              padding-left: 20px;
            }
          }
        }

        .details {
          padding: 25px;
          border-left: 5px solid $green;

          h2 {
            margin-bottom: 10px;
            font-weight: 500;
          }
        }

        .mobile-job-type {
          flex-direction: column;
          padding: 20px 30px;

          .title {
            font-size: 20px;
          }
        }
      }

      &_side-column {
        width: 100%;
  
        .share {
  
          &_header {
            font-size: 24px;
          }
  
          &_icons {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@include mobile {
  .job_view {
    .job-container {  
      &_main-column {
        .main-info {
          .left-container {
            justify-content: unset;
            padding: 0 20px;
            text-align: left;
          }
        }
      }
    }
  }
}