.footer {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;

  &_section-free-consultation {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green;
    height: 144px;

    &_content {

      .title, .text {
        color: $white;
      }
    }

    .button {
      background: $white;
      border: none;
      width: 133px;
      height: 52px;
      line-height: 52px;
      margin-left: 172px;
      font-weight: 700;
      color: $green;
      text-align: center;
      text-decoration: none;
    }
  }

  &_section-claim-school {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 250px;
    background: $dark-grey;
    color: $white;
    line-height: 36px;

    .info {
      max-width: 220px;
    }

    &_logo-block {
      img {
        height: 100px;
        width: 158px;
      }
    }


    .help-in-claim-logo {
      margin-top: 26px;
      background: url(../../images/help.png) no-repeat scroll 5px 8px;
      background-size: 20px;
      padding-left: 30px;
      font-weight: 700;

      a {
        color: $green;
      }
    }

    &_nav {
      display: flex;
      flex-direction: column;
      text-align: right;

      span {
        font-weight: 700;
        font-size: 16px;
      }

      a {
        color: $grey;
        text-decoration: none;
        &:hover {
          color: $green;
        }
      }
    }

    &_sub {
      height: 150px;

      & p {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 0;
      }

      &_content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
  
        input {
          padding-left: 16px;
          height: 50px;
          width: 248px;
          font-weight: 700;
          border: 1px solid #00007c33;
          border-right: none;
          border-radius: 6px 0 0 6px;
          
        }

        button {
          border: none;
          height: 50px;
          width: 50px;
          border-radius: 0 6px 6px 0;
          background-color: $green;
          
          img {
            margin: 0 auto;
          }
        }
      }

      .help-in-claim-sub {
        display: none;
      }
    }
  }

  &_section-grow-with-truckboards {
    position: relative;
    height: 138px;

    &_socials {
      position: absolute;
      top: 50px;
      left: 183px;
      display: flex;
      
      img {
        cursor: pointer;
      }
        
      & a:not(:last-child) {
        margin-right: 25px;
        cursor: pointer;
      }
    }

    &_partners {
      position: absolute;
      top: 23px;
      left: 43%;
      display: flex;

      img {
        cursor: pointer;
      }

      p {
        text-decoration: none !important;
      }

      & a:not(:last-child) {
        text-decoration: none !important;
        margin-right: 25px;
      }

      & a:-webkit-any-link  {
        text-decoration: none;
        color: inherit;
        font-weight: 600;
      }
    }

    &_other {
      position: absolute;
      top: 57px;
      right: 177px;
      display: flex;
      font-weight: 700;
      
      a {
        color: $dark-grey;

        &:hover {
          color: $green;
        }
      }

      & a:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}

.footer-hide {
  display: none;
}

@include tablet {
  .footer {

    &_section-grow-with-truckboards {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      padding: 40px 0;

      &_socials {
        position: static;
        margin-bottom: 20px;
      }

      &_partners {
        position: static;
        margin-bottom: 20px;
      }

      &_other {
        position: static;
        font-weight: 500;
      }
    }

    &_section-free-consultation {
      flex-direction: column;
      height: auto;
      padding: 30px;

      &_content {
        margin-bottom: 15px;
        text-align: center;

        .title {
          margin-bottom: 10px;
        }
      }

      .button {
        width: 35%;
        height: auto;
        margin-left: 0;
        padding: 10px 5px;
        font-size: 16px;
        line-height: 35px;
      }
    }

    &_section-claim-school {
      height: auto;
      padding: 60px 40px;

      &_logo-block {
        margin-bottom: 20px;

        img {
          height: 80px;
          width: 127px;
        }

        .logo-text {
          font-size: 12px;
        }

        .help-in-claim-logo {
          margin-top: unset;
        }
      }

      &_nav {
        justify-content: center;
        line-height: 30px;
        
        span {
          padding-top: 0;
          font-size: 16px;
        }
      }

      &_sub {
        width: 100%;
        height: auto;

        & p {
          font-size: 14px;
          margin-bottom: 10px;
        }

        &_content {
          display: flex;
          justify-content: flex-start;
          margin-top: 0;
          margin-bottom: 20px;

          input {
            height: 35px;
            width: 80%;
            font-weight: 500;
            border-radius: 4px 0 0 4px;
            font-size: 12px;
          }
  
          button {
            height: 35px;
            width: 35px;
            border-radius: 0 4px 4px 0;
          }
        }
  
        .help-in-claim-sub {
          display: block;
          background: url(../../images/help.png) no-repeat scroll 5px 8px;
          background-size: 20px;
          margin: 0;
          padding-left: 30px;
          color: $green;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

@include mobile {
  .footer {

    &_section-free-consultation {
      flex-direction: column;
      height: auto;
      padding: 30px;

      &_content {
        margin-bottom: 10px;

        .title {
          margin-bottom: 10px;
        }
      }

      .button {
        width: 35%;
        height: auto;
        margin-left: 0;
        padding: 10px 5px;
        font-size: 13px;
        line-height: 20px;
      }
    }

    &_section-claim-school {
      flex-direction: column;
      height: auto;
      padding: 60px 40px;

      &_logo-block {
        margin-bottom: 20px;

        .logo-text {
          font-size: 12px;
        }
      }

      &_nav {
        justify-content: center;
        line-height: 20px;
        text-align: center;

        span {
          padding-top: 0;
          font-size: 16px;
        }
      }

      &_sub {
        width: 100%;
        height: auto;

        & p {
          font-size: 14px;
          margin-bottom: 10px;
        }

        &_content {
          display: flex;
          justify-content: flex-start;
          margin-top: 0;
          margin-bottom: 20px;

          input {
            height: 35px;
            width: 80%;
            font-weight: 500;
            border-radius: 4px 0 0 4px;
            font-size: 12px;
          }
  
          button {
            height: 35px;
            width: 35px;
            border-radius: 0 4px 4px 0;
          }
        }
  
        .help-in-claim-sub {
          display: block;
          background: url(../../images/help.png) no-repeat scroll 5px 8px;
          background-size: 20px;
          margin: 0;
          padding-left: 30px;
          color: $green;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    &_section-grow-with-truckboards {
      padding: 20px 0 40px 0;

      &_other {
        flex-direction: column;
        text-align: center;

        & a:not(:last-child) {
          margin-right: unset;
        }
      }
    }
  }
}
