.terms-and-policy {
  background-color: $light-grey;
  font-family: 'Mulish', sans-serif;

  &_content {
    background-color: $white;
    padding: 1vw;
    
    p, li {
      word-wrap: break-word;
    }

    table, td, th {
      border: 1px solid black;
      border-collapse: collapse;
    }

    td, th {
      padding: 1vw;
    }

    tr td:last-child {
      text-align: center;
    }
  }
}