.manage {
  padding: 10px 300px;
  text-align: center;

  h1 {
    color: $green;
  }

  .manage-recruiters,
  .approved-recruiters {
    .recruiters_list {
      display: flex;
      flex-direction: column;
  
      &_item {
        display: flex;
        justify-content: flex-start;
  
        .recruiter-email {
          min-width: 200px;
        }
      }
    }    
  }

  .invite-recruiter {
    .message {
      height: 22px;
      opacity: 1;
    }
    
    .hidden {
      opacity: 0 !important;
    }

    input {
      box-sizing: border-box;
      border-radius: 2px 0 0 2px;
      padding: 7px;
      border: 1px solid $green;

      &:focus {
        outline: none;
      }
    }

    button {
      box-sizing: border-box;
      padding: 8px;
      color: $white;
      background-color: $green;
      border: none;
      border-radius: 0 2px 2px 0;

      &:hover {
        background-color: $dark-green;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: $grey;
      }
    }
  }
}
