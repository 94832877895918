.range-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  &_label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
  }

  p {
    width: fit-content;
    text-align: center;
  }

  .track-wrapper {
    height: 36px;
    display: flex;
    max-width: 300px;
    width: 80vw;
    margin: 0 40px;

    .track {
      height: 5px;
      width: 100%;
      border-radius: 4px;
      align-self: center;
    }
  }

  .thumb {
    height: 30px;
    width: 30px;
    border-radius: 4px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px #aaa;

    &:focus {
      outline: none;
    }

    &_middle {
      height: 16px;
      width: 5px;
      background-color: $green;
    }

    &_middle.disabled {
      background-color: $grey;
    }

    &_label {
      display: block;
      position: absolute;
      top: 35px;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      padding: 4px;
      border-radius: 4px;
      background-color: $green;
      white-space: nowrap,
    }
  }
}

@media (max-width: 650px) {
  .range-container {
    flex-direction: column;
  }
}
