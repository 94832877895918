.application-download {
  display: flex;
  flex-direction: column;
  align-items: center;

  table {
    border-collapse: collapse;
  }

  .section-name {
    display: flex;
  }

  .field {
    width: 200px;
    text-align: center;
  }

  .value {
    height: 25px;
    border-bottom: 1px solid black;
  }

  .name {
    font-size: 12px;
  }
}

.application-page .content {
  position: relative;
  padding-top: 100px;
  flex-direction: column;
  background-color: $light-grey;

  @media (max-width: 1000px) {
    padding-top: 70px;
  }

  @media (max-width: 450px) {
    padding-top: 50px;
  }

  .pre-button-text {
    max-width: 450px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .line {
    margin: 24px 0 30px;
    height: 1px;
    width: 100%;
    background-color: #191010;
  }

  &_banner {
    display: flex;
    align-items: flex-end;
    font-weight: 600;
    width: 90vw;
    max-width: 1000px;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 450px) {
      flex-direction: column-reverse;
      align-items: unset;
    }

    &_left {
      p {
        width: 100%;
        text-align: left;
      }
    }

    &_right {
      p {
        width: 100%;
        text-align: right;

        @media (max-width: 450px) {
          text-align: left;
        }
      }
    }
  }
}
