.popper-container {
  position: relative;
  margin: 0 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .target {
    width: 16px;
    height: 16px;
    background: url(../../images/doubts-button.png) no-repeat;
    border-radius: 50%;
  }

  .target:hover {
    box-shadow: 3px 1px 2px grey;
  }

  .popper {
    position: absolute;
    width: max-content;
    text-align: justify;
    max-width: 200px;
    border-radius: 3px;
    background-color: $light-grey;
    color: black;
    padding: 10px;
    top: 30px;
    z-index: 1000;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 13px -5px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 13px -5px rgba(66, 68, 90, 1);

    p {
      margin: 0;
    }
  }
}
