.not-found {
  background-color: $light-grey;
  padding: 70px;
  text-align: center;
  font-family: 'Mulish', sans-serif;

  h1 {
    margin: 20px;
  }

  a {
    text-decoration: none;
    color: $white;
    background-color: $green;
    padding: 10px 20px;
    line-height: 48px;

    &:hover {
      background-color: $dark-green;
    }
  }
}
