.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: 'Mulish', sans-serif;
  overflow: hidden;
  padding-left: 170px;
  padding-right: 137px;
  overflow: visible;
  height: $standard-header-height;

  @include laptop {
    padding-left: 8vw;
    padding-right: 5vw;
  }

  &_logo-and-search {
    position: relative;
    display: flex;
    & > * + * {
      margin-left: 30px;
    }

    img {
      height: 70px;
      width: 110px;
    }

    .button {
      background: none;
      color: $dark-grey;
      font-size: 14px;
      font-weight: 600;
      border: none !important;
    }

    .dropdown-menu {
      z-index: 668;
      width: 300px;
      max-height: 60vh;
      overflow-y: auto;
      top: 80px;
      left: 140px;
      margin-left: unset;

      @include tablet {
        width: 100%;
        left: 0;
      }

      &_item {
        width: 100%;
        text-align: left;
        padding-left: 33px;
      }
    }
  }

  &_nav {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 5vw;
    }
    font-size: 14px;

    &_links {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: 32px;
      }

      .header_link {
        color: $dark-grey;
        font-weight: 600;
        text-decoration: none;
      }
    }

    .header-button {
      border: none;
      font-weight: 700;
      cursor: pointer;
    }

    .button_login {
      margin: 0 20px;
      background-color: transparent;
      cursor: pointer;
      color: $black;
      height: 42px;

      @media (max-width: 320px) {
        margin: 0 5px;
      }
    }

    .button_start {
      margin: 0 20px;
      height: 42px;
      background-color: $green;
      color: $white;
      padding: 0px 15px;
    }

    &_logged-user {
      display: flex;
      & > * + * {
        margin-left: 27px;
      }
      align-items: center;
      position: relative;

      .button {
        background: none;
        border: none;
      }

      .user_name {
        font-weight: 600;
        color: $green;
        text-align: right;
      }

      .user_img {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 44px;
        width: 44px;
        min-height: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .initials {
        background: rgba(17, 17, 17, 0.48);
        font-weight: 500;
        font-size: 13px;
        color: $white;
      }

      .dropdown-menu {
        z-index: 668;
        top: 69px;
        right: 0px;

        .line {
          margin: 0 auto;
          height: 1px;
          width: 80%;
          background: $black;
        }
      }
    }
  }
}

.header-hide {
  display: none;
}

@include tablet {
  .header {
    padding: 0 20px;
    height: $standard-header-height;

    &_logo-and-search {
      position: initial;
      & > * + * {
        margin-left: unset;
      }

      img {
        height: 60px;
        width: 95px;
      }
    }

    &_nav {
      &_logged-user {
        position: initial;
        & > * + * {
          margin-left: 5px;
        }
      }
    }

    .button_start {
      margin: 0;
    }

    .dropdown-menu {
      top: $standard-header-height + 2px;
      margin-left: unset;

      &_item {
        text-align: left;
        font-size: 20px;
        padding: 20px 15px 20px 70px;

        &_logout {
          position: relative;

          .logout-icon {
            position: absolute;
            top: 32%;
            left: 30px;
            width: 30px;
          }

          .logout-close {
            position: absolute;
            padding: 0;
            border: none;
            background-color: transparent;
            top: 10%;
            right: 0;
            width: 64px;
          }
        }
      }
    }
  }
}

@include mobile {
  .header {
    padding: 0;
    height: $mobile-header-height;

    &_logo-and-search {
      position: initial;
      & > * + * {
        margin-left: unset;
      }
      img {
        height: 50px;
        width: 79px;
        margin: 0 10px;
      }
    }

    &_nav {
      &_logged-user {
        position: initial;
        & > * + * {
          margin-left: unset;
        }
        text-align: right;
      }
    }

    .button_login {
      height: $mobile-header-height;
    }

    .button_start {
      height: $mobile-header-height;
      margin: 0;
    }

    .user_img {
      min-width: 30px;
      width: 30px;
      min-height: 30px;
      height: 30px;
      margin: 0 10px;
    }

    .dropdown-menu {
      top: $mobile-header-height + 2px;

      &_item {
        text-align: left;
        font-size: 16px;
        padding: 20px 15px 20px 70px;

        &_logout {
          position: relative;

          .logout-icon {
            position: absolute;
            top: 32%;
            left: 30px;
            width: 30px;
          }

          img.logout-close {
            position: absolute;
            top: 10%;
            right: 0;
            width: 80px;
          }
        }
      }
    }
  }
}

.company-exists-sub-header {
  font-family: "Mulish", sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  background-color: $status-awaiting-approval;
  padding: 10px;

  button {
    margin-left: 20px;
    border: 2px solid white;
    color: #ff9900;
    background-color: white;
    padding: 5px 10px;
  }
}  