.company-set-up,
.edit-company,
.edit-job {
  display: flex;
  justify-content: center;
  font-family: 'Mulish', sans-serif;
  width: 100%;
  background-color: $light-grey;
  padding-top: 45px;
  padding-bottom: 93px;

  .content {
    background-color: $white;
    max-width: 778px;
    width: 65vw;
    border: 1px solid $border-grey;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 27px 40px 40px 50px;
    overflow: hidden;

    &_min-height {
      min-height: 1023px;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-style: italic;
      color: #373F41;
      width: 100%;
      text-align: right;
    }

    .line {
      margin: 24px 0 30px;
      height: 1px;
      width: 100%;
      background-color: #E3E3E3;
    }

    .company-sign-up-form,
    .company-edit-form,
    .job-edit-form {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      &_upper-section {
        display: flex;
        flex-direction: column;
        width: 100%;

        .search-select {
          max-width: 450px;
          width: 100%;

          @include tablet {
            max-width: 400px;
          }
        }
      }

      &_down-section {
        width: 100%;

        .progress-bar {
          max-width: 500px;
          width: 90vw;
          margin: 40px auto;
        }

        .buttons {
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          .buttons_pages {
            width: 100%;
          }
        }
      }
    }
    .company-edit-form_upper-section.page-0 {
      .field .label {
        margin-left: 0;
      }
    }

    .company-edit-form_upper-section.page-1,
    .company-edit-form_upper-section.page-2 {
      .field .label {
        margin: 10px 0 10px -100px;

        @include laptop {
          margin-left: 0;
        }
      }
    }
  }
}

.company-set-up .company-sign-up-form_down-section {
  .buttons {
    gap: 33px;

    .button {
      width: 235px;
      height: 45px;
      background-color: $green;
      color: $white;
      font-size: 14px;
      letter-spacing: 0.3px;
      font-weight: 700;
      border: none;
    }
  }

  .buttons.column-reverse {
    flex-direction: column-reverse;
  }
}

.company-field-no-spaces {
  margin-top: 0px;
  margin-bottom: 0px;
}

@include laptop {
  .company-set-up,
  .edit-company,
  .edit-job {
    padding-bottom: 45px;

    .content {
      width: 90vw;
    }
  }
}

@include tablet {
  .company-set-up,
  .edit-company,
  .edit-job {
    padding: 0;

    .content {
      max-width: none;
      width: 100%;
      padding: 4%;

      .title {
        text-align: center;
      }

      .input {
        max-width: 400px;
        width: 90vw;
      }

      .buttons {
        gap: 10px;
      }
    }
  
    .pop-up_content {
      .input {
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .company-set-up .nested_form {
    margin-left: -20px;
  }
}

@media (max-width: 500px) {
  .company-set-up .nested_form {
    margin-left: -40px;
  }
}

@include mobile {
  .company-set-up,
  .edit-company,
  .edit-job {
    padding: 0;

    .content {
      width: 100%;
      padding: 15px;

      .title {
        text-align: center;
      }

      .fields-for-mobile .field.field_columns {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      .nested_form {
        margin-left: -20vw;
      } 
      .buttons {
        flex-direction: column-reverse;
        align-items: center;
        width: 90vw;
      }
    }
  }
}
