.admin-list-of-companies {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 76px;

  &_company-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 38px;
    border: 1px solid $border-grey;
    background-color: $white;
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    & > * {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &_avatar {
      width: 30%;
      display: flex;
      align-items: center;
      margin-right: 30px;

      @include laptop() {
        width: 100%;
      }
    }

    &_avatar-img {
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      margin-right: 30px;
      border-radius: 50%;
    }

    &_no-avatar {
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      margin-right: 30px;
      border-radius: 50%;
      background-color: $dark-grey;
    }

    &_name {
      font-size: 20px;
      line-height: 18px;
      font-weight: 700;
    }

    &_network-contacts {
      width: 40%;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      font-size: 14px;

      @include laptop() {
        width: 70%;
      }

      @media (max-width: 685px) {
        width: 100%;
      }
    }

    &_contact {
      width: 100%;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 9px;
      }
    }

    &_contact-header {
      margin-right: 10px;
      line-height: 18px;
      word-break: initial;
    }

    &_contact-content {
      line-height: 18px;
      font-style: italic;
    }

    &_link {
      padding: 12px 20px;
      border: 1px solid $green;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      background-color: $white;
      text-decoration: none;
      color: $green;
  
      &:hover {
        box-shadow: 0 0 2px $green;
      }
    }
  }
}
