.button_scroll {
  border: none;
  font-weight: 700;
  position: fixed;
  top: 87%;
  left: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $green;
  height: 52px;
  width: 52px;
  border: none;
  border-radius: 50%;
  transform: rotate(-90deg);
  z-index: 2;
  cursor: pointer;
}

@include tablet {
  .button_scroll {
    left: 88%;
  }
}

@include mobile {
  .button_scroll {
    right: 5px;
    left: unset;
    bottom: 5px;
    top: unset;
  }
}
