.sign-up-form,
.job-edit-form_upper-section,
.company-edit-form_upper-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .privacy-policy-section {
    line-height: 1.1;
    margin-bottom: 20px;

    .privacy-policy {
      margin: 0;
    }
  }

  .field-wrapper {
    height: fit-content;
  }  

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 21px;

    @include mobile {
      flex-direction: column-reverse;
    }

    .button {
      margin: 4px;
      max-width: 235px;
      width: 100%;
      height: 42px;
      border: none;
      background: $green;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
    }

    .button:disabled {
      background: $transparent-green-2;
    }
  }

  .btn-join {
    width: 100%;
    border: none;
    padding: 5px;
    font-size: 20px;
    font-weight: 700;
    background-color: $light-blue;
    color: $white;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      background-color: lighten($light-blue, 10%);;
    }
  }
}

.sign-up-form,
.company-edit-form_upper-section {
  > .field {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    align-self: flex-start;
    margin: 0;

    .label {
      padding-left: 2px;
      align-self: flex-start;
    }

    .sign-up-recruiter-page_input,
    .sign-up-school-page_input,
    .claim-school-page_input,
    .patch-school_input {
      display: block;
      margin: 9px 0;
      width: 100%;
      height: 34px;
      background: $input-bg;
      mix-blend-mode: normal;
      border: 1px solid $green;
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 8px;
      font-size: 12px;
      font-style: italic;
      color: $black;
    }

    .sign-up-recruiter-page_input::-webkit-input-placeholder,
    .sign-up-school-page_input::-webkit-input-placeholder,
    .claim-school-page_input::-webkit-input-placeholder {
      color:$medium-grey;
    }
  }
}

.company-sign-up-form,
.company-new-job-form,
.company-edit-form,
.job-edit-form,
.application-form {
  display: none !important;
}

.page-active {
  display: flex !important;
}

@include tablet {
  .sign-up-form {
    .field,
    .field_radio,
    .field_columns,
    .field_radio_horizontal {
      display: flex;
      flex-direction: column;
    }

    .field,
    .field_columns {
      align-items: center;
    }

    .field_radio_horizontal {
      align-items: flex-start;
      justify-content: center;
    }

    .field_radio {
      justify-content: flex-start;
    }

    .field_flex {
      .label {
        align-self: center;
      }

      .field_wrapper {
        margin-left: 0;
      }
    }

    .field label,
    .field span{
      max-width: 95vw;
      text-align: center;
    }
    

    .nested_form {
      margin-left: 0 !important;
    }
  }
}
