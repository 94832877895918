.admin-job-price {
  .field_nested_input {
    justify-content: center;

    .field_wrapper {
      text-align: center;
    }

  }
  .settings-page_buttons {
    @media (max-width: 450px) {
      margin: unset;
    }
  }
}